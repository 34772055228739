import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {UserAuthentication} from '../services/user-authentication';
import {Post} from '../Models/Post';

@Component({
  selector: 'app-follow-page',
  templateUrl: './family-friend-page.component.html',
  styleUrls: ['./family-friend-page.component.css']
})

export class FamilyFriendPageComponent implements OnInit, OnDestroy {
  familyIds: number[] = [];
  friendIds: number[] = [];
  followIds: number[] = [];
  private subscriptions: Subscription[] = [];
  editPost: Post = null;
  constructor(
    private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public userAuth: UserAuthentication
    , public router: Router
) { }
  nickname: string;
  userProfile;
  status: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ngOnInit() {
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) { continue; }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            this.userProfile = t;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            this.userProfile = t;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data1 => {
          if (data1) {
            this.userProfile = data1;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.status.subscribe(value => {
      if (value === true) {
        // TODO: load data

        this.loadFamilyList();
        this.loadFriendList();
        this.loadFollowList();
      }
    }));
  }

  loadFamilyList() {
    this.userAuth.getFamilyList(this.userProfile.account.id, result => {
      this.familyIds = [];
      for (const item of result) {
        this.familyIds.push(item.toId);
        this.userAuth.loadUserProfile(item.toId, data => {});
      }
    });
  }

  loadFriendList() {
    this.userAuth.getFriendList(this.userProfile.account.id, result => {
      this.friendIds = [];
      for (const item of result) {
        this.friendIds.push(item.toId);
        this.userAuth.loadUserProfile(item.toId, data => {});
      }
    });
  }
  loadFollowList() {
    this.userAuth.getFollowList(this.userProfile.account.id, result => {
      this.followIds = [];
      for (const item of result) {
        this.followIds.push(item.toId);
        this.userAuth.loadUserProfile(item.toId, data => {});
      }
    });
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }

  generatePost(type) {
    if (!this.editPost) {
      this.editPost = {
        id: null,
        content: '',
        title: '',
        images: [],
        type: 'general',
        published: 1
      };
    }
    if (type === 'place') {
      this.editPost.postCatId = 1;
    } else if (type === 'subject') {
      this.editPost.postCatId = 2;
    }
  }
  updateTo(type: string) {
    this.userAuth.updateFollow(this.userProfile.account.id, type, result => {
      if (result.status) {
        this.loadFriendList();
        this.loadFamilyList();
        this.loadFollowList();
      }
    });
  }
}
