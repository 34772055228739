import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
// import {Router} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import { getJWTToken, GlobalSetting } from './global-setting';
import {BehaviorSubject, throwError} from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpParams } from '@angular/common/http';

let loop_times = 500;

@Injectable({
  providedIn: 'root'
})
export class UserAuthentication {
  public static rules = {
    'home': {'loggedin': false},
    'profile': {'loggedin': true},
    'my-country': {'loggedin': true},
    'myCountry': {'loggedin': true},
    'page': {'loggedin': true},
    'server-config': {'loggedin': true, userIds: [2, 14], groupIds: [1]},
    '': {'loggedin': true},
  };

  get userRules() {
    return UserAuthentication.rules;
  }

  userGroups = [
    {id: 1, intro: 'Quản trị viên'},
    {id: 2, intro: 'Ban giám đốc'},
    {id: 3, intro: 'normal'},
  ];
  functionGroups = [
    {'patern': 'rev-exp-dec', 'name': 'Khai báo loại thu/chi'},
    {'patern': 'accounting-report', 'name': 'Báo cáo kế toán'},
    {'patern': 'student-health', 'name': 'Theo dõi sức khỏe'},
    {'patern': 'class-manage', 'name': 'Quản lý lớp học'},
    {'patern': 'daily-rolling', 'name': 'Điểm danh/chấm cơm'},
    {'patern': 'weekly-rolling', 'name': 'Điểm danh/chấm cơm theo tuần'},
    {'patern': 'class-receipt', 'name': 'Sổ thanh toán/kết chuyển'},
    {'patern': 'accounting-report-statistics', 'name': 'Thống kê tài chính'},
    {'patern': 'daily-rolling-statistics', 'name': 'Thống kê điểm danh/chấm cơm'},
  ];
  urlMapping = {
    login: 'apiCore/login',
    userProfile: 'apiCore/userProfile',
    follow: 'apiCore/follow',
    signup: 'apiCore/signup',
    logout: 'apiCore/logout',
    renewToken: 'apiCore/renewToken',
  };
  userProfileList: BehaviorSubject<any> = new BehaviorSubject({});
  userProfileListByNickname: BehaviorSubject<any> = new BehaviorSubject({});
  public returnUrl: string = null;
  public userData: any = null;
  private _userDataChanged: Subject<any> = new Subject<any>();
  userDataChanged$: Observable<any> = this._userDataChanged.asObservable();
  private _loginErrors: Subject<any> = new Subject<any>();
  loginErrors$: Observable<any> = this._loginErrors.asObservable();
  followData = {};
  private _followDataChanged: Subject<any> = new Subject<any>();
  followDataChanged$: Observable<any> = this._followDataChanged.asObservable();
  constructor(
    public http: HttpClient, public globalSetting: GlobalSetting,
    // private router: Router,
    private jwtHelper: JwtHelperService) {
    this.userDataChanged$.subscribe(userData => {
      if (this.userData.id > 0) {
        this.loadUserProfile(this.userData.id, data => {});
      }
    });
    setTimeout(() => {
      this.trackUser();
    }, loop_times);
  }

  private trackUser() {
    console.log('trackUser is running');
    const userData = this.getAccountInfo();
    if (this.userData === null || this.userData['id'] !== userData['id']) {
      this.userData = userData;
      this._userDataChanged.next(this.userData);
    }
    console.log(this.userData);
    if (loop_times < 60000) {
      loop_times = 120000;
    }
    if (this.userData['id'] > 0) {
      this.renewToken();
    }
    if (this.userData['id'] === 0) {
      // this.returnUrl = this.router.url;
      // this.router.navigate(['home']);
    }
    setTimeout(() => {
      this.trackUser();
    }, loop_times);
  }

  isLoggedIn() {
    const token = getJWTToken();
    if (token === null || token === '') {
      return false;
    }
    try {
      if (this.jwtHelper.isTokenExpired(token)) {
        return false;
      }
      const jwt = this.jwtHelper.decodeToken(token);
      if (jwt && jwt.data) {
      } else {
        return false;
      }
      const data = jwt.data;
      if (data && data.account) {
        return (data.account.id > 0);
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  getAccountInfo() {
    const token = getJWTToken();
    if (!token) {
      return {
        'id': null,
        'name': 'guest',
        'group': 0
      };
    }
    try {
      if (this.jwtHelper.isTokenExpired(token)) {
        console.log('token is expired');
        return {
          'id': null,
          'name': 'guest',
          'group': 0
        };
      }
      const data = this.jwtHelper.decodeToken(token);
      return (data.data && data.data.account) ? data.data.account : {
        'id': null,
        'name': 'guest',
        'group': 0
      };
    } catch (e) {
      return {
        'id': null,
        'name': 'guest',
        'group': 0
      };
    }
  }

  public logout(callback) {
    GlobalSetting.unsetJWTToken(this.globalSetting.serverId);
    this.userData = this.getAccountInfo();
    this._userDataChanged.next(this.userData);
    callback();
  }

  login(body: any, callback) {
    let params = new HttpParams();
    params = params.set('r', this.urlMapping.login);
    this.http.post(this.globalSetting.activeServer.getValue().base_url +
      this.globalSetting.activeServer.getValue().scriptFile, body, {params: params})
      .subscribe(
        (rs: any) => {
          if (rs.errors) {
            const errs = [];
            for (const key in rs.errors) {
              if (rs.errors.hasOwnProperty(key)) {
                errs.push(rs.errors[key]);
              }
            }
            this._loginErrors.next(errs);
            callback({status: false, errors: errs});
          } else {
            this._loginErrors.next([]);
            const rsdata = rs;
            if (rsdata['jwt']) {
              this.globalSetting.token = rsdata.jwt;
              GlobalSetting.changeJWTToken(this.globalSetting.serverId, rsdata.jwt);
              delete rsdata['jwt'];
            }
            this.userData = this.getAccountInfo();
            this._userDataChanged.next(this.userData);
            callback({status: true});
          }
        },
        (err) => {
          if (err) {
            this._loginErrors.next([err.message]);
          } else {
            this._loginErrors.next([err.toString()]);
          }
          // let errMsg = (err.message) ? err.message :
          // 	err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          // return Observable.throw(errMsg);
        },
        () => {
        }// console.log('Request Complete')
      );
  }
  signup(body: any, callback: (data: any) => void) {
    let params = new HttpParams();
    params = params.set('r', this.urlMapping.signup);
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      body, {params: params})
      .subscribe(
        (rs: any) => {
          if (rs.errors) {
            const errs = [];
            for (const key in rs.errors) {
              if (rs.errors.hasOwnProperty(key)) {
                errs.push(rs.errors[key]);
              }
            }
            callback({status: false, errors: errs});
          } else {
            this._loginErrors.next([]);
            const rsdata = rs;
            if (rsdata['jwt']) {
              this.globalSetting.token = rsdata.jwt;
              GlobalSetting.changeJWTToken(this.globalSetting.serverId, rsdata.jwt);
              delete rsdata['jwt'];
            }
            this.userData = this.getAccountInfo();
            this._userDataChanged.next(this.userData);
            callback({status: true});
          }
        },
        err => {
          if (err) {
            this._loginErrors.next([err.message]);
          } else {
            this._loginErrors.next([err.toString()]);
          }
          // let errMsg = (err.message) ? err.message :
          // 	err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          // return Observable.throw(errMsg);
        },
        () => {
        }// console.log('Request Complete')
      );
  }
  validateSignup(body: any, callback) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.signup);
    params = params.set('validate', '1');
    const options = {};
    options['params'] = params;
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      body, options)
      .subscribe(
        rs => {
            callback(rs);
        },
        err => {
          callback({status: true});
        },
        () => {
        }// console.log('Request Complete')
      );
  }

  renewToken(callback = null) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.renewToken);
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          console.log(body);
          if (body.errors) {
            this._loginErrors.next(body.errors); // jwt not valid or server error
          } else {
            const rsdata = body;
            if (rsdata['jwt']) {
              this.globalSetting.token = rsdata.jwt;
              GlobalSetting.changeJWTToken(this.globalSetting.serverId, rsdata.jwt);
              delete rsdata['jwt'];
            }
            const ai = this.getAccountInfo();
            if (this.userData.id !== ai.id) {
              this._userDataChanged.next(this.userData);
            }
            this.userData = ai;
            if (this.userData.id === null || this.userData.id === 0) {
              // this.router.navigate(['home']).then(() => {});
            }
          }
          if (callback !== null) { callback(); }
        },
        err => {
          this._loginErrors.next(err.toString());
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          console.log(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  loadUserProfile(userId: number, callback: (data: any) => void, forceReload = false) {
    const l = this.userProfileList.getValue();
    if (!forceReload && l[userId]) {
      callback(l[userId]);
      return;
    }
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.userProfile);
    params = params.set('id', userId.toString());
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body.errors) {
          } else {
            const rsdata = body;
            if (rsdata.status === true) {
              const list = this.userProfileList.getValue();
              list[userId] = {account: rsdata.account, profile: rsdata.profile};
              callback(list[userId]);
              this.userProfileList.next(list);
            } else {
              callback(null);
            }
          }
        },
        err => {
          callback(null);
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
        },
        () => {
        } // console.log('')
      );
  }
  searchUserProfile(condition: {}, callback: (data: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.userProfile);
    const cList = this.userProfileList.getValue();
    for (const key in condition) {
      if (!condition.hasOwnProperty(key)) { continue; }
      const val = condition[key];
      let index = 0;
      if (Array.isArray(val)) {
        for (const v of val) {
          if (key === 'id') {
            if (cList.hasOwnProperty(v.toString())) {
              continue;
            }
          }
          params = params.set(`${key}[${index}]`, val.toString());
          index++;
        }
      } else {
        params = params.set(key, val.toString());
      }
    }
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          const rsdata = body;
          const list = this.userProfileList.getValue();
          for (const up of rsdata) {
            list[up.account.id] = {account: up.account, profile: up.profile};
          }
          callback(rsdata);
          this.userProfileList.next(list);
        },
        err => {
          callback([]);
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
        },
        () => {}
      );
  }
  loadUserProfileBynickname(nickname: string, callback: (data: any) => void, forceReload = false) {
    const l = this.userProfileList.getValue();
    if (!forceReload) {
      for (const item in l) {
        if (!l.hasOwnProperty(item)) {
          continue;
        }
        const t = l[item];
        if (t.account.nickname === nickname) {
          callback(t);
        }
      }
   }
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.userProfile);
    params = params.set('nickname', nickname);
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body.errors) {
          } else {
            const rsdata = body;
            if (rsdata.status === true) {
              const list = this.userProfileList.getValue();
              list[rsdata.account.id] = {account: rsdata.account, profile: rsdata.profile};
              callback(list[rsdata.account.id]);
              this.userProfileList.next(list);
            } else {
              callback(null);
            }
          }
        },
        err => {
          callback(null);
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  updateProfile(value: any, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.userProfile);
    const options = {};
    options['params'] = params;
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      value, options)
      .subscribe(
        (body: any) => {
          if (body.errors) {
          } else {
            callback(body);
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  getFollowData(userId: number, callback: (result: any) => void, forceLoad = false) {
    if (forceLoad === false && this.followData.hasOwnProperty(userId)) {
      return this.followData[userId];
    }
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body !== false) {
            this.followData[userId] = body;
            this._followDataChanged.next(this.followData);
            callback(body);
          } else {
            callback(null);
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  getFriendList(userId: number, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    params = params.set('type', 'friend');
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body !== false) {
            callback(body);
          } else {
            callback(null);
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return throwError(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  getFamilyList(userId: number, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    params = params.set('type', 'family');
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body !== false) {
            callback(body);
          } else {
            callback(null);
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return throwError(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  getFollowList(userId: number, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    params = params.set('type', 'follow');
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          if (body !== false) {
            callback(body);
          } else {
            callback(null);
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return throwError(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  follow(userId: string, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    const options = {};
    options['params'] = params;
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, {}, options)
      .subscribe(
        (body: any) => {
          callback(body);
          if (body.status) {
            const list = this.userProfileList.getValue();
            if (list.hasOwnProperty(body.followingProfile.accountId)) {
              list[body.followingProfile.accountId].profile = body.followingProfile;
            }
            if (list.hasOwnProperty(body.profile.accountId)) {
              list[body.profile.accountId].profile = body.profile;
            }
            this.userProfileList.next(list);
          } else {
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  updateFollow(userId: string, type: string, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    const options = {};
    options['params'] = params;
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      {type}, options)
      .subscribe(
        (body: any) => {
          callback(body);
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }
  unfollow(userId: string, callback: (result: any) => void) {
    let params: HttpParams = new HttpParams();
    params = params.set('r', this.urlMapping.follow);
    params = params.set('id', userId.toString());
    const options = {};
    options['params'] = params;
    this.http.delete(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (body: any) => {
          callback(body);
          if (body.status) {
            const list = this.userProfileList.getValue();
            if (list.hasOwnProperty(body.followingProfile.accountId)) {
              list[body.followingProfile.accountId].profile = body.followingProfile;
            }
            if (list.hasOwnProperty(body.profile.accountId)) {
              list[body.profile.accountId].profile = body.profile;
            }
            this.userProfileList.next(list);
          } else {
          }
        },
        err => {
          const errMsg = (err.message) ? err.message :
            err.status ? `${err.status} - ${err.statusText}` : 'Server error';
          return Observable.throw(errMsg);
        },
        () => {
        } // console.log('')
      );
  }

  get isGuest(): boolean {
    return !this.isLoggedIn();
  }

  public checkGroup(routePath): boolean {
    const accountInfo = this.getAccountInfo();
    const isLogin = this.isLoggedIn();
    const rule = UserAuthentication.rules[routePath];
    let result: boolean = null;
    if (rule) {
      for (const key in rule) {
        if (!(rule.hasOwnProperty(key))) {
          continue;
        }
        const value = rule[key];
        switch (key) {
          case 'loggedin':
            result =  (value) ? isLogin : true;
            break;
          case 'groupIds':
            const test = value.indexOf(accountInfo.groupId) >= 0;
            if (test === false) {
              result = false;
            } else {
              if (result === null) {
                result = true;
              }
            }
            break;
          case 'userIds':
            const test1 = value.indexOf(accountInfo.id) >= 0;
            if (test1 === false) {
              result = false;
            } else {
              if (result === null) {
                result = true;
              }
            }
        }
      }
    } else {
      result = true;
    }
    return result;
  }
}
