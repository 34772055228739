import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {slideInDownAnimation} from '../animations';
import {UserAuthentication} from '../services/user-authentication';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {MyCountryService} from '../services/my-country.service';
import {MyCountry} from '../Models/MyCountry';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-my-country-type-page',
  templateUrl: './my-country-type-page.component.html',
  styleUrls: ['./my-country-type-page.component.css'],
  animations: [slideInDownAnimation]
})
export class MyCountryTypePageComponent implements OnInit, OnDestroy {
  public type;
  public followData: {followedIds: number[], followingIds: number[]} = {followedIds: [], followingIds: []};
  @HostBinding('@routeAnimation') routeAnimation = true;
  private subscriptions: Subscription[] = [];
  public nickname: string;
  public userProfile = null;
  public likeOrDontLike = null;
  myCountries: MyCountry[] = [];
  lastId = null;
  isLoadding = true;
  endOfList = false;
  editPost: MyCountry = null;
  status: BehaviorSubject<{ [id: string]: boolean }> = new BehaviorSubject({});
  constructor(
    private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public userAuth: UserAuthentication
    , public router: Router
    , public myCountryService: MyCountryService
  ) { }

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.data['type'];
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
            const status1 = this.status.getValue();
            if (!status1.hasOwnProperty('userProfile') || status1['userProfile'] === false) {
              status1['userProfile'] = true;
              this.status.next(status1);
            }
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
      if (params.hasOwnProperty('likeOrDontLike')) {
        this.likeOrDontLike = params['likeOrDontLike'];
      }

      const status = this.status.getValue();
      if (!status.hasOwnProperty('routeParams') || status['routeParams'] === false) {
        status['routeParams'] = true;
        this.status.next(status);
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) {
          continue;
        }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            const oup = this.userProfile;
            this.userProfile = t;
            const status = this.status.getValue();
            if (oup === null || !status.hasOwnProperty('userProfile')) {
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            const oup = this.userProfile;
            this.userProfile = t;
            const status = this.status.getValue();
            if (oup === null || !status.hasOwnProperty('userProfile')) {
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data => {
          if (data) {
            const oup = this.userProfile;
            this.userProfile = data;
            const status = this.status.getValue();
            if (oup === null || !status.hasOwnProperty('userProfile')) {
              status['userProfile'] = true;
              this.status.next(status);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.status.subscribe(value => {
      if (value.hasOwnProperty('userProfile') && value.hasOwnProperty('routeParams')
        && value['routeParams'] && value['userProfile']) {
        // TODO: load data
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
            this.userAuth.loadUserProfile(followingItem.toId, () => {});
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
          }
          this.loadMyCountry();
          // this.loadPost();
        }, true);
      }
    }));
  }
  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }
  loadMyCountry() {
    const condition = {authorId: [this.userProfile.account.id], lastId: this.lastId};
    // show wall page
    if (!this.nickname && this.followData.followingIds) {
      for (const id of this.followData.followingIds) {
        condition.authorId.push(id);
      }
    }
    if (this.likeOrDontLike === 'i-like') {
      condition['iLike'] = 1;
    } else if (this.likeOrDontLike === 'i-dont-like') {
      condition['iLike'] = 0;
    }

    if (this.type === 'my-country') {
      condition['countryId'] = this.userProfile.profile.countryId;
    } else if (this.type === 'your-country') {
      condition['notCountryId'] = this.userProfile.profile.countryId;
    }
    this.myCountryService.find(condition, l => {
      if (l.length === 0) {
        this.endOfList = true;
      }
      for (const item of l) {
        if (!this.lastId) {
          this.lastId = item.id;
        } else if ( this.lastId > item.id) {
          this.lastId = item.id;
        }
        this.myCountries.push(item);
      }
      this.isLoadding = false;
    });
  }
  follow() {
    if (this.followData.followedIds.indexOf(this.userAuth.userData.id) >= 0) {
      this.userAuth.unfollow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;

              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    }
  }
  generatePostCallback() {
    return (data) => {
      if (this.userAuth.userData && this.userProfile.account.id === this.userAuth.userData.id) {
        this.updatePostsAfterSave(data);
      } else {
      }
    };
  }
  updatePostsAfterSave(editPost: MyCountry) {
    let found = false;
    const l = [];
    for (const p of this.myCountries) {
      if (editPost.id === p.id) {
        l.push(JSON.parse(JSON.stringify(editPost)));
        this.editPost = null;
        found = true;
      } else {
        l.push(p);
      }
    }
    if (found === false) {
      l.unshift(JSON.parse(JSON.stringify(editPost)));
      this.editPost = null;
    }
    this.myCountries = l;
  }
  generatePost() {
    if (!this.editPost) {
      this.editPost = {
        id: null,
        content: '',
        title: '',
        images: [],
        countryId: null,
        published: 1
      };
    }
  }
  onScroll() {
    this.isLoadding = true;
    if (this.endOfList === false) {
      this.loadMyCountry();
    }
  }
}
