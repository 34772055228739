import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNiceHtml'
})
export class ToNiceHtmlPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/(\r\n|\n|\r)/gm, '<br>');
  }
}
