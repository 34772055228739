import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Subscription} from 'rxjs/Subscription';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-server-config',
  templateUrl: './server-config.component.html',
  styleUrls: ['./server-config.component.css']
})
export class ServerConfigComponent implements OnInit {
  subscriptions: Subscription[] = [];
  modalRef: BsModalRef;
  config = null;
  constructor(
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    public globalSetting: GlobalSetting) {
    this.globalSetting.getServerConfig(true, config => {this.config = config; });
  }

  ngOnInit() {
  }
  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
      })
    );
    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {
      })
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? ', dismissed by ${reason}' : '';
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.unsubscribe();
      }));

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);
  }
  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  generateUpdateCoverFunc() {
    return (value) => {
      this.receiveCover(value);
    };
  }
  receiveCover(value) {
    // TODO: save to server
    console.log(value);
    if (this.config === null) {
      this.config = {homeCover : value.filename};
    } else {
      this.config.homeCover = value.filename;
    }
    this.globalSetting.setServerConfig(this.config, config => {this.config = config; this.modalRef.hide(); });
  }
}
