import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-image-list-input',
  templateUrl: './image-list-input.component.html',
  styleUrls: ['./image-list-input.component.css']
})
export class ImageListInputComponent implements OnInit {
  @Input() images: string[];
  @Output()
  imagesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  constructor(public globalSetting: GlobalSetting) { }
  generateReceiveNewImageFunc() {
    return (value) => {
      this.receiveNewImage(value);
    };
  }
  ngOnInit() {
    if (this.images === null) { this.images = []; }
  }
  receiveNewImage(value) {
    // TODO: save to server
    console.log(value)
    this.images.push(value.filename);
    this.imagesChange.emit(this.images);
  }
  removeImage(index: number) {
    this.images.splice(index, 1);
  }
}
