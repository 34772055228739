import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {UserAuthentication} from '../services/user-authentication';
import {Post} from '../Models/Post';

@Component({
  selector: 'app-follow-page',
  templateUrl: './follow-page.component.html',
  styleUrls: ['./follow-page.component.css']
})

export class FollowPageComponent implements OnInit, OnDestroy {
  followData: {followedIds: number[], followingIds: number[]} = {followedIds: [], followingIds: []};
  private subscriptions: Subscription[] = [];
  editPost: Post = null;
  constructor(
    private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public userAuth: UserAuthentication
    , public router: Router
) { }
  nickname: string;
  userProfile;
  status: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ngOnInit() {
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) { continue; }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            this.userProfile = t;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            this.userProfile = t;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data1 => {
          if (data1) {
            this.userProfile = data1;
            if (!this.status.getValue()) {
              this.status.next(true);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.status.subscribe(value => {
      if (value === true) {
        // TODO: load data
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
            this.userAuth.loadUserProfile(followingItem.toId, data => {});
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
            this.userAuth.loadUserProfile(followedItem.fromId, data => {});
          }
        }, true);
      }
    }));
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }

  generatePost(type) {
    if (!this.editPost) {
      this.editPost = {
        id: null,
        content: '',
        title: '',
        images: [],
        published: 1,
        type: 'general'
      };
    }
    if (type === 'place') {
      this.editPost.postCatId = 1;
    } else if (type === 'subject') {
      this.editPost.postCatId = 2;
    }
  }
  follow() {
    if (this.followData.followedIds.indexOf(this.userAuth.userData.id) >= 0) {
      this.userAuth.unfollow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, fresult => {
              }, true);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;

              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, fresult => {
              }, true);
            }
          }, true);
        }
      });
    }
  }
}
