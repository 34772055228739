import {Injectable} from '@angular/core';
import {GlobalSetting} from './global-setting';
import {City, Country} from '../Models/general-models';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralDataService {
  public countryList: BehaviorSubject<{ [id: number]: Country }> = new BehaviorSubject({});
  public cityList: BehaviorSubject<{ [id: number]: City }> = new BehaviorSubject({});
  private _url = 'apiGeneralData';
  private fullSizeCityList: { [id: number]: City } = {};
  public areaCodes = {
    'e_asia': {name: 'Eastern Asia'},
    'sc_asia': {name: 'South-central Asia'},
    'se_asia': {name: 'South-eastern Asia'},
    'w_asia': {name: 'Western Asia'},
    'e_europe': {name: 'Eastern Europe'},
    'n_europe': {name: 'Northern Europe'},
    's_europe': {name: 'Southern Europe'},
    'w_europe': {name: 'Western Europe'},
    'e_africa': {name: 'Eastern Africa'},
    'm_africa': {name: 'Middle Africa'},
    'n_africa': {name: 'Northern Africa'},
    's_africa': {name: 'Southern Africa'},
    'w_africa': {name: 'Western Africa'},
    'latc': {name: 'Latin America and the Caribbean'},
    'c_america': {name: 'Central America'},
    's_america': {name: 'South America'},
    'n_america': {name: 'Northern America'},
    'oceania': {name: 'Oceania'},
  };
  constructor(public http: HttpClient, public globalSetting: GlobalSetting) {

  }

  public create(model: string, data, callback: (result) => void) {
    this.globalSetting.loadingMap['GDL.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('model', model);
    const options = {};
    options['params'] = params;
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => {
        delete this.globalSetting.loadingMap['GDL.create'];
      });
  }

  public find(conditions: {}, callback: (list: any[]) => void) {
    this.globalSetting.loadingMap['GDL.find'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) { continue; }
      const v = conditions[k];
      if (v instanceof Array) {
        params = params.set(k, v.join(','));
      } else if (typeof v === 'string') {
        params = params.set(k, v);
      } else {
        params = params.set(k, v.toString());
      }
    }
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe((list: any[]) => {
        if (callback) { callback(list); }
      }, error => {
        if (callback) { callback([]); }
      }, () => { delete this.globalSetting.loadingMap['GDL.find']; });
  }

  public loadCountry(reload = false, callback: (list: { [countryCode: number]: Country }) => void = null) {
    const clist = {};
    if (reload === false || Object.keys(clist).length > 0) {
      this.find({
        model: 'Country'
      }, list => {
        for (const a of list) {
          clist[a.countryCode] = a;
        }
        this.countryList.next(clist);
        if (callback) { callback(clist); }
      });
    } else {
      if (callback) { callback(clist); }
    }
  }

  public loadCity(reload = false, callback: (list: { [id: number]: City }) => void = null) {
    const clist = this.cityList.getValue();
    if (reload === false || Object.keys(clist).length > 0) {
      this.find({
        model: 'City'
      }, list => {
        for (const a of list) {
          clist[a.id] = a;
        }
        this.cityList.next(clist);
        if (callback) { callback(clist); }
      });
    } else {
      if (callback) { callback(clist); }
    }
  }

  buildFullSizeCityList(reload = false, callback: (list: { [id: number]: City }) => void) {
    if (Object.keys(this.fullSizeCityList).length > 0 && reload === false) {
      callback(this.fullSizeCityList);
      return;
    }
    this.fullSizeCityList = {};
    this.loadCity(reload, list => {
      this.loadCountry(reload, countries => {
        for (const id in list) {
          if (!list.hasOwnProperty(id)) { continue; }
          const cityData = list[id];
          if (countries[cityData.countryId]) {
            cityData.country = countries[cityData.countryId];
          }
          this.fullSizeCityList[id] = cityData;
        }
        callback(this.fullSizeCityList);
      });
    });
  }
}
