import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeHelperService} from '../services/time-helper.service';
import {Post} from '../Models/Post';
import {PostService} from '../services/post.service';

@Component({
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.css']
})
export class PostFormComponent implements OnInit {
  @Input() post: Post;
  @Input() callback: (post: Post) => void;
  @Output('post') change: EventEmitter<Post> = new EventEmitter<Post>();
  constructor(public timeHelper: TimeHelperService, public postService: PostService) {
  }

  ngOnInit() {
  }

  save(iLike) {
    if (this.post.id) {
      this.postService.edit(this.post.id, this.post, result => {
        if (result.status) {
          this.post = result.data;
          this.change.emit(this.post);
          this.callback(this.post);
        }
      });
    } else {
      this.post.iLike = iLike;
      this.postService.create(this.post, result => {
        if (result.status) {
          this.post = result.data;
          this.change.emit(this.post);
          this.callback(this.post);
        }
      });
    }
  }
  saveMyPlace() {
    if (this.post.id) {
      this.postService.edit(this.post.id, this.post, result => {
        if (result.status) {
          this.post = result.data;
          this.change.emit(this.post);
          this.callback(this.post);
        }
      });
    } else {
      this.post.iLike = 1;
      console.log(this.post)
      this.postService.create(this.post, result => {
        if (result.status) {
          this.post = result.data;
          this.change.emit(this.post);
          this.callback(this.post);
        }
      });
    }
  }
}
