import {Component, NgZone} from '@angular/core';
import {UserAuthentication} from './services/user-authentication';
import {TimeHelperService} from './services/time-helper.service';
import {GlobalSetting} from './services/global-setting';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isNavbarCollapsed1 = true;
  isNavbarCollapsed2 = true;
  yearNo;
  userProfile;
  signinForm: FormGroup;
  errors = [];
  keyword = '';
  signupErrors = null;
  constructor(public userAuth: UserAuthentication,
              public formBuilder: FormBuilder,
              private zone: NgZone,
              public router: Router,
              public timeHelper: TimeHelperService,
              private titleService: Title,
              public globalSetting: GlobalSetting) {
    this.yearNo = this.timeHelper.getCurrentDate('YYYY');
    this.userAuth.userProfileList.subscribe(list => {
      if (userAuth.userData) {
        this.userProfile = list[userAuth.userData.id.toString()];
      }
    });
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(120)])],
    });
    this.titleService.setTitle(this.globalSetting.pageTitle);
  }
  logout() {
    this.userAuth.logout(() => {this.zone.run(() => {}); });
  }
  signin() {
    const data = this.signinForm.value;
    this.errors = [];
    this.userAuth.login({username: data.email, password: data.password}, (rs) => {
      if (rs.status === false) {
        this.errors = rs.errors;
      } else {
        this.errors = [];
        if (this.userAuth.returnUrl) {
          this.router.navigateByUrl(this.userAuth.returnUrl);
        } else {
          this.router.navigate(['page']);
        }
      }
    });
  }
}
