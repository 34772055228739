import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserAuthentication} from '../services/user-authentication';
import {Router} from '@angular/router';
import {ValidateHelper} from '../services/validate-helper';
import {GeneralDataService} from '../services/general-data.service';
import {GlobalSetting} from '../services/global-setting';
import {CarouselConfig} from 'ngx-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 6000, noPause: true, showIndicators: false, noWrap: false } }
  ]
})
export class HomeComponent implements OnInit {
  signupForm: FormGroup;
  signinForm: FormGroup;
  errors = [];
  signupErrors = null;
  config = null;
  constructor(public formBuilder: FormBuilder, public zone: NgZone, public auth: UserAuthentication, public router: Router
  , private validateHelper: ValidateHelper
  , private generalData: GeneralDataService
  , public globalSetting: GlobalSetting
  ) {
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.maxLength(120)])],
      lastName: ['', Validators.compose([Validators.maxLength(120)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
      nickname: ['', Validators.compose([Validators.required, Validators.maxLength(32)])],
      typePass: ['', Validators.compose([Validators.required, Validators.maxLength(120)])],
      reTypePass: ['', Validators.compose([Validators.required, Validators.maxLength(120)])],
      residence: ['', Validators.compose([Validators.required, Validators.maxLength(120)])],
      countryCode: ['', Validators.compose([Validators.required, Validators.maxLength(8)])],
      gender: ['', Validators.compose([Validators.required, Validators.maxLength(8)])],
    }, {validator: this.validateHelper.checkPasswords});
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(120)])],
    });
    this.globalSetting.getServerConfig(true, config => {
      console.log(config);
      this.zone.run(() => { console.log(config); this.config = config; });
    });
  }
  countryList = [];
  ngOnInit() {
    this.generalData.loadCountry();
    this.generalData.countryList.subscribe((list: any) => {
      this.countryList = [];
      const listByArea = this.generalData.areaCodes;
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          if (!listByArea[list[key]['areaCode']].hasOwnProperty('children')) {
            listByArea[list[key]['areaCode']]['children'] = [];
          }
          listByArea[list[key]['areaCode']]['children'].push(list[key]);
        }
      }
      for (const ac in listByArea) {
        if (listByArea.hasOwnProperty(ac)) {
          this.countryList.push(listByArea[ac]);
        }
      }
    });
    if (this.auth.userData && this.auth.userData.id > 0) {
      console.log(this.auth.userData);
      if (this.auth.returnUrl) {
        this.router.navigateByUrl(this.auth.returnUrl);
      } else {
        this.router.navigate(['page']);
      }
    }
    this.auth.userDataChanged$.subscribe(value => {
      console.log(value);
      console.log(this.auth.returnUrl);
      if (value.id > 0) {
        if (this.auth.returnUrl) {
          this.router.navigateByUrl(this.auth.returnUrl);
        } else {
          this.router.navigate(['page']);
        }
      }
    });
    this.signupForm.valueChanges.subscribe(() => {
      this.auth.validateSignup(this.signupForm.value, result => {
        if (result.status) {
          this.signupErrors = null;
        } else {
          this.signupErrors = result.errors;
        }
      });
    });
  }

  signin() {
    const data = this.signinForm.value;
    this.errors = [];
    this.auth.login({username: data.email, password: data.password}, (rs) => {
      if (rs.status === false) {
        console.log(rs.errors);
        this.errors = rs.errors;
      } else {
        this.errors = [];
        if (this.auth.returnUrl) {
          this.router.navigateByUrl(this.auth.returnUrl);
        } else {
          this.router.navigate(['page']);
        }
      }
    });
  }
  signup() {
    const data = this.signupForm.value;
    this.auth.signup(data, (rs) => {
      if (rs.status === false) {
        this.errors = rs.errors;
      } else {
        this.errors = [];
        if (this.auth.returnUrl) {
          this.router.navigateByUrl(this.auth.returnUrl);
        } else {
          this.router.navigate(['page']);
        }
      }
    });
  }
}
