import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserAuthentication} from './user-authentication';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private history = [];
  constructor(public userAuth: UserAuthentication, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkGroup(route.routeConfig.path, state.url);
  }

  public checkGroup(routePath, url, doNavigate = true): boolean {
    const isLogin = this.userAuth.isLoggedIn();
    const result = this.userAuth.checkGroup(routePath);
    if (result === false) {
      if (isLogin) {
        if (doNavigate) {
          const backurl = this.history.pop();
          alert('Bạn không thể thực hiện hành động này!');
          if (backurl && backurl !== '/') {
            this.router.navigateByUrl(backurl);
          } else {
            this.router.navigate(['/']);
          }
        }
        return result;
      }
      this.userAuth.returnUrl = url;
      if (doNavigate) {
        this.router.navigate(['home']);
      }
    }
    if (doNavigate) {
      this.history.push(url);
    }
    return true;
  }
}
