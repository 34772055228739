import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descToNiceHtml'
})
export class DescToNiceHtmlPipe implements PipeTransform {
  transform(value: string, length: number = 60, args?: any): any {
    value = value.substring(0, length);
    value = value.substr(0, Math.min(value.length, value.lastIndexOf(' ')));
    return value.replace(/(\r\n|\n|\r)/gm, '<br>');
  }
}
