import {Inject, Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import * as numeral from 'numeral';
import {DataStorage} from './DataStorage';
import {Subject} from 'rxjs/Subject';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {DOCUMENT} from '@angular/common';
export function  getJWTToken() {
    let serverId = DataStorage.getItem('serverId');
    if (serverId == null) {
      serverId = 'geogoe';
    }
    const tokens = DataStorage.getJSONItem(GlobalSetting.TOKEN_KEY);
    if (tokens == null) {
      return null;
    }
    let token = null;
    for (const id in tokens) {
      if (!tokens.hasOwnProperty(id)) { continue; }
      if (id === serverId) {
        token = tokens[id];
      }
    }
    if (token === null) {
      delete tokens[serverId];
      DataStorage.setJSONItem(GlobalSetting.TOKEN_KEY, tokens);
    }
    return token;
  }
@Injectable({
  providedIn: 'root'
})
export class GlobalSetting {
  static TOKEN_KEY = 'geogoe_token';
  isServerChanged: Subject<string> = new Subject();
  pageTitle = 'Geogoe';
  pageSubTitle = '';
  sidebarCollapsed = true;
  public domainName: string;
  token: string;
  isLoading = false;
  loadingMap = [];
  lastActiveServerId = 'geogoe';
  chartColorSets = {
    red: {
      backgroundColor: 'rgba(226,33,36,0.3)',
      borderColor: 'rgba(226,33,36,1)',
      pointBackgroundColor: 'rgba(226,33,36,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(226,33,36,0.8)'
    },
    'orange': {
      backgroundColor: 'rgba(244,164,93,0.3)',
      borderColor: 'rgba(244,164,93,1)',
      pointBackgroundColor: 'rgba(244,164,93,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(244,164,93,0.8)'
    },
    'yellow': {
      backgroundColor: 'rgba(255,238,16,0.3)',
      borderColor: 'rgba(255,238,16,1)',
      pointBackgroundColor: 'rgba(255,238,16,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255,238,16,0.8)'
    },
    'lemon': {
      backgroundColor: 'rgba(184,203,0,0.3)',
      borderColor: 'rgba(184,203,0,1)',
      pointBackgroundColor: 'rgba(184,203,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(184,203,0,0.8)'
    },
    'green': {
      backgroundColor: 'rgba(79,172,58,0.3)',
      borderColor: 'rgba(79,172,58,1)',
      pointBackgroundColor: 'rgba(79,172,58,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(79,172,58,0.8)'
    },
    'blue': {
      backgroundColor: 'rgba(2,163,155,0.3)',
      borderColor: 'rgba(2,163,155,1)',
      pointBackgroundColor: 'rgba(2,163,155,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(2,163,155,0.8)'
    },
    'light-blue': {
      backgroundColor: 'rgba(98,194,234,0.3)',
      borderColor: 'rgba(98,194,234,1)',
      pointBackgroundColor: 'rgba(98,194,234,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(98,194,234,0.8)'
    },
    'cobalt-blue': {
      backgroundColor: 'rgba(0,119,187,0.3)',
      borderColor: 'rgba(0,119,187,1)',
      pointBackgroundColor: 'rgba(0,119,187,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,119,187,0.8)'
    },
    'purple': {
      backgroundColor: 'rgba(73,50,138,0.3)',
      borderColor: 'rgba(73,50,138,1)',
      pointBackgroundColor: 'rgba(73,50,138,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(73,50,138,0.8)'
    },
    'violet': {
      backgroundColor: 'rgba(170,121,176,0.3)',
      borderColor: 'rgba(170,121,176,1)',
      pointBackgroundColor: 'rgba(170,121,176,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(170,121,176,0.8)'
    },
    'pink': {
      backgroundColor: 'rgba(233,79,141,0.3)',
      borderColor: 'rgba(233,79,141,1)',
      pointBackgroundColor: 'rgba(233,79,141,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(233,79,141,0.8)'
    },
  };
  private _serverConfig = null;
  activeServer: BehaviorSubject<{
    id: string, name: string, base_url: string, displayName: string,
    address?: string, phone?: string, website?: string, scriptFile?: string }>
    = new BehaviorSubject({
    id: 'geogoe',
    name: 'GEOGOE',
    base_url: 'https://www.geogoe.com',
    scriptFile: '/api.php/',
    displayName: 'GEOGOE Social Network',
  });
  // static datepickerConfig: IDatePickerConfig = {
  //   locale: 'vi',
  //   format: 'DD/MM/YYYY',
  //   allowMultiSelect: false,
  //   firstDayOfWeek: 'mo',
  //   monthFormat: 'MM/YYYY'
  // };
  static getJWTToken = () => {
    let serverId = DataStorage.getItem('serverId');
    if (serverId == null) {
      serverId = 'geogoe';
    }
    const tokens = DataStorage.getJSONItem(GlobalSetting.TOKEN_KEY);
    if (tokens == null) {
      return null;
    }
    let token = null;
    for (const id in tokens) {
      if (id === serverId) {
        token = tokens[id];
      }
    }
    if (token === null) {
      delete tokens[serverId];
      DataStorage.setJSONItem(GlobalSetting.TOKEN_KEY, tokens);
    }
    return token;
  };

  static getTokenFunction() {
    return () => {
      let serverId = DataStorage.getItem('serverId');
      if (serverId == null) {
        serverId = 'geogoe';
      }
      const tokens = DataStorage.getJSONItem(GlobalSetting.TOKEN_KEY);
      if (tokens == null) {
        return null;
      }
      let token = null;
      for (const id in tokens) {
        if (id === serverId) {
          token = tokens[id];
        }
      }
      if (token === null) {
        delete tokens[serverId];
        DataStorage.setJSONItem(GlobalSetting.TOKEN_KEY, tokens);
      }
      return token;
    };
  }

  static unsetJWTToken(serverId) {
    const tokens = DataStorage.getJSONItem(GlobalSetting.TOKEN_KEY);
    if (tokens[serverId]) {
      delete tokens[serverId];
    }
    DataStorage.setJSONItem(GlobalSetting.TOKEN_KEY, tokens);
  }

  static changeJWTToken(serverId, token) {
    let tokens = DataStorage.getJSONItem(GlobalSetting.TOKEN_KEY);
    if (tokens === null) {
      tokens = {};
    }
    tokens[serverId] = token;
    DataStorage.setJSONItem(GlobalSetting.TOKEN_KEY, tokens);
  }

  static _formatNumber(tooltipItem, data) {
    const dataset = data.datasets[tooltipItem.datasetIndex];
    const datasetLabel = dataset.label || '';
    return datasetLabel + ': ' + numeral(dataset.data[tooltipItem.index]).format('0,0[.]00');
  }

  constructor(public translate: TranslateService, public http: HttpClient, @Inject(DOCUMENT) private document: any) {
    this.domainName = this.document.location.hostname;
    console.log(this.domainName);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
    this.serverId = DataStorage.getItem('serverId');
    numeral.register('locale', 'fr', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'n',
        million: 'tr',
        billion: 't',
        trillion: 'nT'
      },
      ordinal: function (number) {
        return '';
      },
      currency: {
        symbol: 'đ'
      }
    });
    numeral.locale('fr');
    this.getServerConfig(false, (config) => {
    });
  }

  formatNumber(tooltipItem, data) {
    return GlobalSetting._formatNumber(tooltipItem, data);
  }

  formatYScaleNumber(value) {
    if (/^[-+]?([0-9]*\.[0-9]+|[0-9]+)$/.test(value)) {
      return numeral(value).format('0[.]0 a');
    } else {
      return value;
    }
  }

  formatFullNumber(value) {
    if (/^[-+]?([0-9]*\.[0-9]+|[0-9]+)$/.test(value)) {
      return numeral(value).format('0,0[.]00');
    } else {
      return value;
    }
  }

  get availableServers(): { id: string, name: string, base_url: string, displayName: string, scriptFile: string }[] {
    return [
      {
        id: 'geogoe',
        name: 'GEOGOE',
        base_url: '',
        scriptFile: '/api.php/',
        displayName: 'GEOGOE Social Network',
      },
    ];
  }

  get serverId() {
    let serverId = DataStorage.getItem('serverId');
    if (!serverId) {
      serverId = 'geogoe';
    }
    let foundServer = false;
    this.availableServers.every((value, index) => {
      if (value.id === serverId) {
        if (this.activeServer.getValue().id !== serverId) {

          if (this.lastActiveServerId !== this.activeServer.getValue().id) {
            this.lastActiveServerId = this.activeServer.getValue().id;
          }
          this.activeServer.next(value);
          this.isServerChanged.next(serverId);
          this.pageTitle = value.name;
        }
        foundServer = true;
        return false;
      }
      return true;
    });
    if (!foundServer) {
      DataStorage.setItem('serverId', 'geogoe');
      serverId = 'geogoe';
      this.availableServers.every((value, index) => {
        if (value.id === serverId) {
          if (this.activeServer.getValue().id !== serverId) {
            if (this.lastActiveServerId !== this.activeServer.getValue().id) {
              this.lastActiveServerId = this.activeServer.getValue().id;
            }
            this.activeServer.next(value);
            this.isServerChanged.next(serverId);
            this.pageTitle = value.name;
          }
          foundServer = true;
          return false;
        }
        return true;
      });
    }
    return serverId;
  }

  set serverId(sId) {
    let foundServer = false;
    this.availableServers.every((value) => {
      if (value.id === sId) {
        if (this.activeServer.getValue().id !== sId) {
          if (this.lastActiveServerId !== this.activeServer.getValue().id) {
            this.lastActiveServerId = this.activeServer.getValue().id;
          }
          this.activeServer.next(value);
          this.isServerChanged.next(sId);
          this.pageTitle = value.name;
        }
        foundServer = true;
        return false;
      }
      return true;
    });
    if (!foundServer) {
      DataStorage.setItem('serverId', sId);
      // throw new RangeError('Không tìm thấy serverId mà bạn đã chọn!');
    } else {
      DataStorage.setItem('serverId', sId);
    }
  }

  addLoadingMap(value) {
    if (this.loadingMap.lastIndexOf(value) === -1) {
        this.loadingMap.push(value);
    }
  }

  removeLoadingMap(value) {
    if (this.loadingMap.lastIndexOf(value) !== -1) {
      this.loadingMap.splice(this.loadingMap.lastIndexOf(value), 1);
    }
  }

  getServerConfig(reload = false, callback: (config: any) => void) {
    if (reload === false && this._serverConfig) {
      callback(this._serverConfig);
      return this._serverConfig;
    }
    this.loadingMap['getServerConfig'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', 'apiCore/serverConfig');
    this.http.get(this.activeServer.getValue().base_url + this.activeServer.getValue().scriptFile, {params: params})
      .subscribe(list => {
        this._serverConfig = list;
        console.log(list);
        callback(list);
      }, error => {
        console.log(error);
        this._serverConfig = null;
        if (callback) {
          callback(null);
        }
      }, () => {
        delete this.loadingMap['getServerConfig'];
      });
  }
  setServerConfig(data, callback: (config: any) => void) {
    this.loadingMap['getServerConfig'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', 'apiCore/serverConfig');
    const options = {};
    options['search'] = params;
    this.http.post(this.activeServer.getValue().base_url + this.activeServer.getValue().scriptFile, data, options)
      .subscribe((list: any) => {
        if (list.status) {
          this._serverConfig = list.data;
        } else {

        }
        if (callback) { callback(this._serverConfig); }
      }, error => {
        this._serverConfig = null;
        if (callback) { callback(null); }
      }, () => {
        delete this.loadingMap['getServerConfig'];
      });
  }
}
