import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {isNumeric} from 'rxjs/util/isNumeric';
import Moment = moment.Moment;

moment.locale('vi');

@Injectable({
  providedIn: 'root'
})
export class TimeHelperService {

  public static vi_date_format = 'DD/MM/YYYY';
  public static vi_time_format = 'HH:mm:ss DD/MM/YYYY';
  public static en_date_format = 'MM/DD/YYYY';
  public static en_time_format = 'HH:mm:ss MM/DD/YYYY';
  public static sql_date_format = 'YYYY-MM-DD';
  public static sql_time_format = 'YYYY-MM-DD HH:mm:ss';

  static getCurrentDateString(format = 'DD/MM/YYYY') {
    const obj = moment();
    return obj.format(format);
  }

  static getCurrentUnixTimeStamp(asMillisecond = false) {
    const obj = moment();
    if (asMillisecond) { return obj.valueOf(); }
    return obj.unix();
  }

  static parseDateTime(date: string, format: string | string[], asMillisecond: boolean = false): number | boolean {
    const obj = moment(date, format);
    if (!obj.isValid()) {
      return false;
    } else {
      return (asMillisecond) ? obj.valueOf() : obj.unix();
    }
  }

  static getLastDateOfMonth(date: any, toFormat: string = 'D', fromFormat: string = null): string {
    let obj;
    // console.log('date: '+date);
    if (fromFormat) {
      obj = moment(date, fromFormat);
    } else if (typeof date === 'string') {
      if (date.length > 10) { obj = moment(+date); } else { obj = moment.unix(+date); }
    } else if (date.toString().length === 10) {
      obj = moment.unix(+date);
    } else { obj = moment(date); }
    if (obj.isValid()) {
      // console.log(obj.format(TimeHelperService.en_date_format));
      // console.log(obj.endOf('month').format(TimeHelperService.en_date_format));
      return obj.endOf('month').format(toFormat);
    }
    return null;
  }

  static getDatesInMonth(date: any, toFormat: string, fromFormat: string = null) {
    let obj: Moment;
    if (fromFormat) {
      obj = moment(date, fromFormat);
    } else if (typeof date === 'string') {
      if (date.length > 10) {
        obj = moment(+date);
      } else {
        obj = moment.unix(+date);
      }
    } else {
      obj = moment(date);
    }
    if (obj.isValid()) {
      const days = obj.daysInMonth();
      obj.startOf('month');
      const list = [];
      for (let i = 1; i <= days; i++) {
        list.push(obj.format(toFormat));
        obj.add(1, 'days');
      }
      return list;
    }
    return false;
  }

  static getFirstDateOfMonth(date: any, toFormat: string = 'D', fromFormat: string = null): string {
    const obj =  (fromFormat) ? moment(date, fromFormat) : moment(date);
    if (obj.isValid()) {
      return obj.startOf('month').format(toFormat);
    } else { return null; }
  }

  static changeFormat(date: string, fromFormat: string, toFormat: string): string | boolean {
    if (!date) { return date; }
    const obj = moment(date, fromFormat);
    if (!obj.isValid()) {
      return false;
    } else {
      return obj.format(toFormat);
    }
  }

  static getMonthLastDate(month: string = '', format = 'MM/YYYY', returnFormat = 'DD/MM/YYYY') {
    let obj: Moment;
    if (month === '') {
      obj = moment();
    } else {
      obj = moment(month, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.endOf('month').format(returnFormat);
  }

  static getWeekEndDate(strDate: string = '', format = 'DD/MM/YYYY', returnFormat = 'DD/MM/YYYY') {
    let obj: Moment;
    if (strDate === '') {
      obj = moment();
    } else {
      obj = moment(strDate, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.endOf('week').format(returnFormat);
  }

  static getWeekStartDate(strDate: string = '', format = 'DD/MM/YYYY', returnFormat = 'DD/MM/YYYY') {
    let obj: Moment;
    if (strDate === '') {
      obj = moment();
    } else {
      obj = moment(strDate, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.startOf('week').format(returnFormat);
  }

  static getNextMonth(month: string = '', format = 'MM/YYYY', returnFormat = 'MM/YYYY') {
    let obj: Moment;
    if (month === '') {
      obj = moment();
    } else {
      obj = moment(month, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.add(1, 'months').format(returnFormat);
  }

  static getNextWeek(strDate: string = '', format = 'DD/MM/YYYY', returnFormat = 'DD/MM/YYYY') {
    let obj: Moment;
    if (strDate === '') {
      obj = moment();
    } else {
      obj = moment(strDate, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.add(1, 'weeks').format(returnFormat);
  }

  static getPreviousMonth(month: string = '', format = 'MM/YYYY', returnFormat = 'MM/YYYY'): string {
    let obj: Moment;
    if (month === '') {
      obj = moment();
    } else {
      obj = moment(month, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.subtract(1, 'months').format(returnFormat);
  }

  static getPreviousWeek(strDate: string = '', format = 'DD/MM/YYYY', returnFormat = 'DD/MM/YYYY') {
    let obj: Moment;
    if (strDate === '') {
      obj = moment();
    } else {
      obj = moment(strDate, format);
      if (!obj.isValid()) { obj = moment(); }
    }
    return obj.subtract(1, 'weeks').format(returnFormat);
  }

  static getUnixTimeStamp(date: string = null, format = 'DD/MM/YYYY', includeMS = false) {
    let obj: Moment;
    if (date === null || date === '') {
      obj = moment();
    } else {
      obj = moment(date, format);
      if (!obj.isValid()) { return 0; }
    }
    if (includeMS) { return obj.valueOf(); }
    return obj.unix();
  }

  static changeMomentLocale(code: string) {
    moment.locale(code);
  }

  static getTimeAgo(time: number | string, format: string = null): string {
    const obj = moment(time);
    return (!obj.isValid()) ? '' : moment.duration(obj.diff(moment())).humanize(true);
  }

  static formatDateTime(unix: number, format: string, isMillisecond: boolean = false) {
    let obj;
    if (isMillisecond) {
      obj = moment(unix);
    } else {
      obj = moment.unix(unix);
    }
    return obj.format(format);
  }

  static getDateList(from, to, format = TimeHelperService.vi_date_format, returnFormat = TimeHelperService.vi_date_format) {
    let obj1: Moment, obj2: Moment;
    obj1 = moment(from, format);
    obj2 = moment(to, format);
    const result = [];
    if (obj1.isValid() && obj2.isValid()) {
      result.push(obj1.format(returnFormat));
      for (let i = 1; i < 35; i++) {
        obj1.add(1, 'day');
        if (obj1.unix() > obj2.unix()) { break; }
        result.push(obj1.format(returnFormat));
      }
    }
    return result;
  }

  static _getNearWeekFromMonth(strMonth, format = 'YYYY-MM', returnFormat = 'YYYY-MM-DD'): { start: string, end: string } {
    const c = moment();
    const strCurMonth = c.format(format);
    const month = moment(strMonth, format);
    let start, end: Moment;
    if (strMonth === strCurMonth) { // same as current month, get current week
      start = moment(c).isoWeekday(1);
      end = moment(c).isoWeekday(7);
      if (end.format('YYYY-MM') !== strMonth) { // ngày cuối cùng không cùng tháng, lấy ngày cuối tháng
        end = moment(month).endOf('month');
      }
    } else if (c.valueOf() > month.valueOf()) { // this month is before current month, get last week
      const eom = moment(month).endOf('month');
      start = moment(eom).isoWeekday(1);
      end = eom;
    } else { // this month is after current month, get first week
      const som = moment(month).startOf('month');
      end = moment(som).isoWeekday(7);
      start = som;
    }
    return {start: start.format(returnFormat), end: end.format(returnFormat)};
  }

  static _getNextWeekFromDate(strDate, format = 'YYYY-MM-DD', returnFormat = 'YYYY-MM-DD'): { start: string, end: string } {
    const dateObj = moment(strDate, format);
    let start, end: Moment;
    start = moment(dateObj).add(1, 'week').isoWeekday(1);
    end = moment(start).isoWeekday(7);
    if (dateObj.format('YYYY-MM') !== start.format('YYYY-MM')) { return null; }
    if (dateObj.format('YYYY-MM') !== end.format('YYYY-MM')) {
      end = moment(dateObj).endOf('month');
    }
    return {start: start.format(returnFormat), end: end.format(returnFormat)};
  }

  static _getPreviousWeekFromDate(strDate, format = 'YYYY-MM-DD', returnFormat = 'YYYY-MM-DD', inSameMonth = false):
  { start: string, end: string } {
    const dateObj = moment(strDate, format);
    let start, end: Moment;
    start = moment(dateObj).subtract(1, 'week').isoWeekday(1);
    end = moment(start).isoWeekday(7);
    if (dateObj.format('YYYY-MM') !== start.format('YYYY-MM')) {
      start = moment(dateObj).startOf('month');
    }
    if (dateObj.format('YYYY-MM') !== end.format('YYYY-MM')) {
      return null;
    }
    return {start: start.format(returnFormat), end: end.format(returnFormat)};
  }

  constructor() {
  }

  public static dayInMonth(vDay, vMonth, mformat) {
    const obj = moment(vMonth, mformat);
    return (vDay >= 1 && vDay <= obj.daysInMonth());
  }

  public static getMonthAge(birthDate: string, birthFormat = 'YYYY-MM-DD', fromDate = null, fromFormat = null): string {
    const obj = moment(birthDate, birthFormat);
    let curr;
    curr = (fromDate && fromFormat) ? moment(fromDate, fromFormat) : curr = moment();
    if (obj.isValid()) {
      if (!curr.isValid()) { curr = moment(); }
      return curr.diff(obj, 'months').toString();
    }
    return 'N/A';
  }

  getTimeStamp(asMicrotime: boolean = false): number {
    const d = new Date();
    const ms = d.getTime();
    return (asMicrotime) ? ms : Math.floor(ms / 1000);
  }

  getCurrentTime(includeMicrotime: boolean = false): string {
    const d = new Date();
    const h = this.addZero(d.getHours(), 2);
    const m = this.addZero(d.getMinutes(), 2);
    const s = this.addZero(d.getSeconds(), 2);

    if (includeMicrotime) {
      const ms = this.addZero(d.getMilliseconds(), 3);
      return h + ':' + m + ':' + s + ':' + ms;
    } else {
      return h + ':' + m + ':' + s;
    }
  }

  getCurrentDate(format: string = 'DD/MM/YYYY'): string {
    const obj = moment();
    return obj.format(format);
  }

  addZero(x, n) {
    while (x.toString().length < n) {
      x = '0' + x;
    }
    return x;
  }

  formatDateTime(date: any) {
    let d: Date;
    if (isNumeric(date)) {
      d = new Date(date);
    } else if (typeof date.getFullYear === 'function') {
      d = date;
    }
    const yyyy = d.getFullYear();
    const mm = this.addZero(d.getMonth(), 2);
    const dd = this.addZero(d.getDate(), 2);
    const h = this.addZero(d.getHours(), 2);
    const m = this.addZero(d.getMinutes(), 2);
    return h + ':' + m + ' ' + dd + '/' + mm + '/' + yyyy;
  }

  parseDateTime(date: string, format: string | string[], asMillisecond: boolean = false): number | boolean {
    return TimeHelperService.parseDateTime(date, format, asMillisecond);
  }

  getLastDateOfMonth(date: any, toFormat: string = 'D', fromFormat: string = null): string {
    return TimeHelperService.getLastDateOfMonth(date, toFormat, fromFormat);
  }

  getDatesInMonth(date: any, toFormat: string, fromFormat: string = null) {
    return TimeHelperService.getDatesInMonth(date, toFormat, fromFormat);
  }

  getFirstDateOfMonth(date: any, toFormat: string = 'D', fromFormat: string = null): string {
    return TimeHelperService.getFirstDateOfMonth(date, toFormat, fromFormat);
  }

  format(date: any, fromFormat: string, toFormat: string): string | boolean {
    let obj;
    obj = moment(date, fromFormat);
    return (!obj.isValid()) ? false : obj.format(toFormat);
  }

  getNearWeekFromMonth(strMonth: string, format = 'YYYY-MM', returnFormat = 'YYYY-MM-DD'): { start: string, end: string } {
    return TimeHelperService._getNearWeekFromMonth(strMonth, format, returnFormat);
  }

  getNextWeekFromDate(strDate: string, format = 'YYYY-MM-DD', returnFormat = 'YYYY-MM-DD'): { start: string, end: string } {
    return TimeHelperService._getNextWeekFromDate(strDate, format, returnFormat);
  }

  getPreviousWeekFromDate(strDate: string, format = 'YYYY-MM-DD', returnFormat = 'YYYY-MM-DD', inSameMonth = false):
  { start: string, end: string } {
    return TimeHelperService._getPreviousWeekFromDate(strDate, format, returnFormat, inSameMonth);
  }
}
