import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Story} from '../Models/Story';
import {TimeHelperService} from '../services/time-helper.service';
import {StoryService} from '../services/story.service';
import {StoryCategory} from '../Models/StoryCategory';

@Component({
  selector: 'app-story-form',
  templateUrl: './story-form.component.html',
  styleUrls: ['./story-form.component.css']
})
export class StoryFormComponent implements OnInit {
  @Input() story: Story;
  @Input() storyCats: StoryCategory[];
  @Input() callback: (story: Story) => void;
  @Output() storyChange: EventEmitter<Story> = new EventEmitter<Story>();
  constructor(public timeHelper: TimeHelperService, public storyService: StoryService) {
  }

  ngOnInit() {
  }

  save() {
    if (this.story.id) {
      this.storyService.edit(this.story.id, this.story, result => {
        if (result.status) {
          this.story = result.data;
          this.storyChange.emit(this.story);
          this.callback(this.story);
        }
      });
    } else {
      this.storyService.create(this.story, result => {
        if (result.status) {
          this.story = result.data;
          this.storyChange.emit(this.story);
          this.callback(this.story);
        }
      });
    }
  }
}
