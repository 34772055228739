import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {slideInDownAnimation} from '../animations';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Story} from '../Models/Story';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalSetting} from '../services/global-setting';
import {UserAuthentication} from '../services/user-authentication';
import {StoryService} from '../services/story.service';
import {SwapDataService} from '../services/swap-data.service';
import {TranslateService} from '@ngx-translate/core';
import {StoryCategory} from '../Models/StoryCategory';

@Component({
  selector: 'app-story-page',
  templateUrl: './story-page.component.html',
  styleUrls: ['./story-page.component.css'],
  animations: [slideInDownAnimation]
})
export class StoryPageComponent implements OnInit, OnDestroy {
  @HostBinding('@routeAnimation') routeAnimation = true;
  private subscriptions: Subscription[] = [];
  followData: { followedIds: number[], followingIds: number[] } = {followedIds: [], followingIds: []};
  page = 1;
  storyLastId = null;
  stories: Story[] = [];
  storyCats: StoryCategory[] = [];
  storyCatSlug: string;
  storyIsLoading = true;
  storyEndOfList = false;
  nickname: string = null;
  userProfile = null;
  status: BehaviorSubject<{ [id: string]: boolean }> = new BehaviorSubject({});
  editStory: Story = null;
  edit = false;
  constructor(private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public userAuth: UserAuthentication
    , public router: Router
    , private storyService: StoryService
    , private swapData: SwapDataService
    , private translate: TranslateService) { }

  ngOnInit() {
    this.storyService.findCat({}, list => {
      const status1 = this.status.getValue();
      status1['storyCatList'] = true;
      this.storyCats = list;
      this.status.next(status1);
    });
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
            const status1 = this.status.getValue();
            if (!status1.hasOwnProperty('userProfile') || status1['userProfile'] === false) {
              status1['userProfile'] = true;
              this.status.next(status1);
            }
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
      if (params.hasOwnProperty('slug')) {
        this.storyCatSlug = params['slug'];
        const statusS = this.status.getValue();
        statusS['cat-slug'] = true;
        this.status.next(statusS);
      }

      const status = this.status.getValue();
      if (!status.hasOwnProperty('routeParams') || status['routeParams'] === false) {
        status['routeParams'] = true;
        this.status.next(status);
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) { continue; }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            const up = this.userProfile;
            this.userProfile = t;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            const up = this.userProfile;
            this.userProfile = t;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data1 => {
          if (data1) {
            const up = this.userProfile;
            this.userProfile = data1;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.status.subscribe(value => {
      console.log(value);
      if (value.hasOwnProperty('userProfile') && value.hasOwnProperty('routeParams') && value.hasOwnProperty('storyCatList') &&
        value['userProfile'] && value['storyCatList'] && value['routeParams']) {
        // TODO: load data
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
            this.userAuth.loadUserProfile(followingItem.toId, () => {});
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
          }
          this.loadStory();
        }, true);
      }
    }));
  }
  loadStory(forceLoad = false) {
    const condition: {[key: string]: any} = {authorId: [this.userProfile.account.id], lastId: this.storyLastId};
    if (this.nickname
    // !this.userAuth.userData || this.userProfile.account.id !== this.userAuth.userData.id
    ) {
      // show another user page
    } else {
      // show wall page
      if (this.followData.followingIds) {
        for (const id of this.followData.followingIds) {
          condition.authorId.push(id);
        }
      }
    }
    if (this.storyCatSlug) {
      const storyCat = this.storyCats.find(item => item.alias === this.storyCatSlug);
      if (storyCat) {
        condition.storyCatId = storyCat.id.toString();
      }
    }
    this.storyService.find(condition, list => {
      if (list.length === 0) {
        this.storyEndOfList = true;
      }
      for (const item of list) {
        this.userAuth.loadUserProfile(item.authorId, () => {
        });
        this.stories.push(item);
        if (this.storyLastId === null) {
          this.storyLastId = item.id;
        } else if (this.storyLastId > item.id) {
          this.storyLastId = item.id;
        }
      }
      this.storyIsLoading = false;
    });
  }
  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }
  generateStory() {
    if (!this.editStory) {
      this.editStory = {
        id: null,
        content: '',
        title: '',
        images: [],
        published: 1
      };
    }
  }
  generateStoryCallback() {
    return (storyEdit: Story) => {
      if (this.userAuth.userData && this.userProfile.account.id === this.userAuth.userData.id) {
        this.updateStorysAfterSave(storyEdit);
      } else {
      }
    };
  }
  updateStorysAfterSave(storyEdit) {
    let found = false;
    const l = [];
    for (const p of this.stories) {
      if (storyEdit.id === p.id) {
        l.push(storyEdit);
        this.editStory = null;
        found = true;
      } else {
        l.push(p);
      }
    }
    if (found === false) {
      l.push(this.editStory);
    }
    this.editStory = null;
    this.stories = l;
  }
  onScroll() {
    this.storyIsLoading = true;
    if (this.storyEndOfList === false) {
      this.loadStory(true);
    }
  }

  follow() {
    if (this.followData.followedIds.indexOf(this.userAuth.userData.id) >= 0) {
      this.userAuth.unfollow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    }
  }

  editStoryData(story: Story) {
    this.edit = true;
    this.editStory = story;
  }

  deleteStoryData(story: Story) {
    this.translate.get('Are you sure to want delete this story?').subscribe(cmessage => {
      if (confirm(cmessage)) {
        this.storyService.delete(story.id, result => {
          if (result.status) {
            const l = [];
            for (const p of this.stories) {
              if (story.id === p.id) {
              } else {
                l.push(p);
              }
            }
            this.stories = l;
            this.translate.get('This story was deleted!').subscribe(message => {
              alert(message);
            });
          }
        });
      }
    });
  }

}
