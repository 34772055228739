import { Injectable } from '@angular/core';
import {GlobalSetting} from './global-setting';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserImage } from 'src/app/Models/general-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserImageService {
  imageList: BehaviorSubject<any> = new BehaviorSubject({});
  private _url = 'apiImage';
  constructor(private globalSetting: GlobalSetting, private http: HttpClient) { }
  public find(conditions: {}, callback: (list: any[]) => void) {
    this.globalSetting.loadingMap['UIS.find'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) {
        continue;
      }
      const v = conditions[k];
      if (v instanceof Array) {
        params.set(k, v.join(','));
      } else if (typeof v === 'string') {
        params.set(k, v);
      } else {
        params.set(k, v.toString());
      }
    }
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe((list: UserImage[]) => {
        if (callback) { callback(list); }
        delete this.globalSetting.loadingMap['UIS.find'];
      }, error => {
        if (callback) { callback([]); }
        delete this.globalSetting.loadingMap['UIS.find'];
      });
  }

  public create(data, callback: (result) => void) {
    this.globalSetting.loadingMap['UIS.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    const options = {};
    options['params'] = params;
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe((result: any) => {
        callback(result);
        delete this.globalSetting.loadingMap['UIS.create'];
      }, error => {
        callback(null);
        delete this.globalSetting.loadingMap['UIS.create'];
      });
  }

  public edit(filename, data, callback: (result) => void) {
    this.globalSetting.loadingMap['UIS.edit'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('filename', filename);
    const options = {};
    options['params'] = params;
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(
        (result: any) => { callback(result); },
        error => { callback(null); },
        () => { delete this.globalSetting.loadingMap['UIS.edit']; });
  }

  public deleteImage(filename: string, callback: (result) => void) {
    this.globalSetting.loadingMap['UIS.delete'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('filename', filename);
    const options = {};
    options['params'] = params;
    this.http.delete(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(
        (result: any) => { callback(result); },
        error => { callback(null); },
        () => { delete this.globalSetting.loadingMap['UIS.delete']; });
  }
}
