import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {FormControl, FormGroup} from '@angular/forms';
import {TimeHelperService} from './time-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateHelper {
  constructor() {

  }

  DateValidate(control: FormControl) {
    // if(control.errors['required']) return null;
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    if (control.value instanceof Date) {
      console.log(control.value);
    } else if (control.value.match(/^[0-3][0-9]\/[0-1][0-9]\/[12][0-9]{3}$/)) {
      const ts = TimeHelperService.parseDateTime(control.value, 'DD/MM/YYYY');
      if (ts === false) {
        return {'invalidDate': true};
      } else {
        return null;
      }
    } else {
      return {'invalidDate': true};
    }
  }

  NumberValidate(control: FormControl) {
    // if(control.errors['required']) return null;
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    if (!isNaN(control.value)) {
      return null;
    } else {
      return {'invalidNumber': true};
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.typePass.value;
    const confirmPass = group.controls.reTypePass.value;
    return pass === confirmPass ? null : {notSame: true};
  }
}
