import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuardService} from './services/auth-guard.service';
import {UserPageComponent} from './user-page/user-page.component';
import {ProfileComponent} from './profile/profile.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PostViewComponent} from './post-view/post-view.component';
import {MyCountryViewComponent} from './my-country-view/my-country-view.component';
import {MyContryPageComponent} from './my-contry-page/my-contry-page.component';
import {FollowPageComponent} from './follow-page/follow-page.component';
import {ServerConfigComponent} from './server-config/server-config.component';
import {PostPageComponent} from './post-page/post-page.component';
import {MyCountryTypePageComponent} from './my-country-type-page/my-country-type-page.component';
import {StoryPageComponent} from './story-page/story-page.component';
import {StoryViewComponent} from './story-view/story-view.component';
import {UserStoryPageComponent} from './user-story-page/user-story-page.component';
import {FamilyFriendPageComponent} from './family-friend-page/family-friend-page.component';
import { MyPlacesComponent } from './my-place/components/my-places/my-places.component';
import { MyPlacesDetailsComponent } from './my-place/components/my-places-details/my-places-details.component';

const appRoutes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/home'},
  {path: 'private', children: [
      {path: '', component: StoryPageComponent, canActivate: [AuthGuardService]},
      {path: 'story-cat/family-friends', component: FamilyFriendPageComponent, canActivate: [AuthGuardService]},
      {path: 'story-cat/:slug', component: StoryPageComponent, canActivate: [AuthGuardService]},
    ]
  },
  {
    path: 'private/:userId', children: [
      {path: 'stories', component: UserStoryPageComponent, canActivate: [AuthGuardService]},
      {path: 'stories/:id/:alias', component: StoryViewComponent, canActivate: [AuthGuardService]},
      {path: 'friends', component: FollowPageComponent, canActivate: [AuthGuardService]},
      {path: 'friends/:type', component: FollowPageComponent, canActivate: [AuthGuardService]}
    ]
  },
  {
    path: 'page', children: [
      {path: '', component: UserPageComponent, canActivate: [AuthGuardService]},
      {path: 'in/:likeOrDontLike', component: UserPageComponent, canActivate: [AuthGuardService]},
      {path: 'my-your-country', component: MyContryPageComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
      {path: 'my-country', component: MyCountryTypePageComponent, canActivate: [AuthGuardService], data: {type: 'my-country'}},
      {
        path: 'your-country',
        component: MyCountryTypePageComponent,
        canActivate: [AuthGuardService],
        data: {type: 'your-country'}
      },
      {
        path: 'my-country/in/:likeOrDontLike',
        component: MyCountryTypePageComponent,
        canActivate: [AuthGuardService],
        data: {type: 'my-country'}
      },
      {
        path: 'your-country/in/:likeOrDontLike',
        component: MyCountryTypePageComponent,
        canActivate: [AuthGuardService],
        resolve: {type: 'your-country'}
      },
      {path: 'my-your-country/in/:likeOrDontLike', component: MyCountryViewComponent, canActivate: [AuthGuardService]},
      {path: 'post', component: PostPageComponent, canActivate: [AuthGuardService]},
      {path: 'post/in/:likeOrDontLike', component: PostPageComponent, canActivate: [AuthGuardService]},
      {
        path: ':userId', children: [
          {path: '', component: UserPageComponent, canActivate: [AuthGuardService]},
          {path: 'in/:likeOrDontLike', component: UserPageComponent, canActivate: [AuthGuardService]},
          {path: 'post', component: PostPageComponent, canActivate: [AuthGuardService]},
          {path: 'post/in/:likeOrDontLike', component: PostPageComponent, canActivate: [AuthGuardService]},
          {path: 'post/:id/:alias', component: PostViewComponent, canActivate: [AuthGuardService]},
          {path: 'my-your-country', component: MyContryPageComponent, canActivate: [AuthGuardService]},
          {
            path: 'my-country', component: MyCountryTypePageComponent, canActivate: [AuthGuardService],
            data: {type: 'my-country'}
          },
          {
            path: 'my-country/in/:likeOrDontLike',
            component: MyCountryTypePageComponent,
            canActivate: [AuthGuardService],
            data: {type: 'my-country'}
          },
          {
            path: 'your-country', component: MyCountryTypePageComponent, canActivate: [AuthGuardService],
            resolve: {type: 'your-country'}
          },
          {
            path: 'your-country/in/:likeOrDontLike',
            component: MyCountryTypePageComponent,
            canActivate: [AuthGuardService],
            resolve: {type: 'your-country'}
          },
          {
            path: 'my-your-country/in/:likeOrDontLike',
            component: MyContryPageComponent,
            canActivate: [AuthGuardService]
          },
          {path: 'my-your-country/:id/:alias', component: MyCountryViewComponent, canActivate: [AuthGuardService]},
          {path: 'follow', component: FollowPageComponent, canActivate: [AuthGuardService]},
          {path: 'follow/:type', component: FollowPageComponent, canActivate: [AuthGuardService]},

        ]
      },
      {path: '**', redirectTo: '/page'},
    ]
  },

  {
    path: 'my-places',
    children: [
      {
        path: '',
        component: MyPlacesComponent,
      },
      {
        path: ':id',
        component: MyPlacesDetailsComponent,
      }
    ]
  },
  // {path: 'page/:userId/post/:id', component: PostViewComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  // {path: 'page/:userId/myCountry/:id', component: PostViewComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: '404', component: PageNotFoundComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: 'server-config', component: ServerConfigComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: '*', component: PageNotFoundComponent}
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
