import {Pipe, PipeTransform} from '@angular/core';
import * as numeral from 'numeral';
import * as moment from 'moment';
import { TimeHelperService } from '../services/time-helper.service';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, fromFormat: string, toFormat: string): string {
    // let tempNum = numeral(value);
    // console.log(tempNum);
    let unix: number;
    // if(tempNum.value() !== NaN){
    // 	unix = tempNum.value();
    // }
    // else {
    if (fromFormat === 'moment' && value instanceof moment) {
      return value.format(toFormat);
    }
    const temp = TimeHelperService.parseDateTime(value, fromFormat);
    if (temp !== false) {
      unix = <number> temp;
    } else {
      return value;
    }
    // }
    return TimeHelperService.formatDateTime(unix, toFormat);
  }

}
