import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {UserAuthentication} from '../services/user-authentication';
import {LikeService} from '../services/like.service';
import {CommentService} from '../services/comment.service';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-like-comment',
  templateUrl: './like-comment.component.html',
  styleUrls: ['./like-comment.component.css']
})
export class LikeCommentComponent implements OnInit, OnDestroy {
  @Input() modelName: string;
  @Input() modelId: number;
  @Input() disableLike = false;
  @Input() disableComment = false;
  likeList: any[];
  commentList: any[];
  commentTree: any;
  selfLike = false;
  editCommentData;
  commentData;
  constructor(public userAuth: UserAuthentication, public likeService: LikeService, public commentService: CommentService
              , public zone: NgZone
              , public globalSetting: GlobalSetting
  ) { }
  subs = [];
  ngOnInit() {
    this.likeService.find({modelName: this.modelName, modelId: this.modelId}, result => {
      this.likeList = result;
      const uIds = [];
      for (const likeItem of this.likeList) {
        if (this.userAuth.userData && this.userAuth.userData.id === likeItem.userId) {
          this.selfLike = true;
        }
        uIds.push(likeItem.userId);
        this.userAuth.loadUserProfile(likeItem.userId, data => {});
      }
      this.zone.run(() => {});
    });
    this.commentService.find({modelName: this.modelName, modelId: this.modelId}, result => {
      this.commentList = result;
      this.buildCommentTree();
      this.zone.run(() => {});
    });
    if (this.userAuth.userData && this.userAuth.userData.id > 0) {
      this.commentData = {
        modelName: this.modelName,
        modelId: this.modelId,
        content: '',
        parentId: 0
      };
    }
    this.subs.push(this.userAuth.userDataChanged$.subscribe(value => {
      console.log('In LikeComment Component');
      console.log(this.userAuth.userData);
      if (this.userAuth.userData.id > 0) {
        this.commentData = {
          modelName: this.modelName,
          modelId: this.modelId,
          content: '',
          parentId: 0
        };
      } else {
        this.commentData = null;
      }
      this.zone.run(() => {});
    if (value.id > 0) {
      this.commentData = {
        modelName: this.modelName,
        modelId: this.modelId,
        content: '',
        parentId: 0
      };
    } else {
      this.commentData = null;
    }
    this.zone.run(() => {});
    }));
  }
  buildCommentTree() {
    this.commentTree = [];
    for (const comm of this.commentList) {
      this.userAuth.loadUserProfile(comm.userId, data => {});
      if (!comm.parentId || comm.parentId === 0) {
        this.commentTree.push(comm);
        continue;
      }
      let notFound = true;
      for (let i = 0; i < this.commentTree.length; i++) {
        const pComm = this.commentTree[i];
        if (pComm.id === comm.parentId) {
          if (!pComm.hasOwnProperty('replies')) {
            pComm['replies'] = [];
          }
          pComm.replies.push(comm);
          notFound = false;
          break;
        }
      }
      if (notFound) {
        this.commentTree.push(comm);
      }
    }
  }
  likeThis () {
    if (this.selfLike) {
        this.likeService.delete(this.modelName, this.modelId, result => {
          this.likeService.find({modelName: this.modelName, modelId: this.modelId}, list => {
            this.likeList = list;
            this.selfLike = false;
            for (const likeI of this.likeList) {
              if (this.userAuth.userData && this.userAuth.userData.id === likeI.userId) {
                this.selfLike = true;
                break;
              }
            }
            this.zone.run(() => {});
          });
        });
    } else {
      this.likeService.create({
        modelName: this.modelName,
        modelId: this.modelId
      }, result => {
        this.likeService.find({modelName: this.modelName, modelId: this.modelId}, list => {
          this.likeList = list;
          this.selfLike = false;
          for (const likeItem of this.likeList) {
            if (this.userAuth.userData && this.userAuth.userData.id === likeItem.userId) {
              this.selfLike = true;
              break;
            }
          }
          this.zone.run(() => {});
        });
      });
    }
  }
  upComment() {
    this.commentService.create(this.commentData, result => {
      if (result.status) {
        this.commentData = {
          modelName: this.modelName,
          modelId: this.modelId,
          content: '',
          parentId: 0
        };

        this.commentService.find({modelName: this.modelName, modelId: this.modelId}, list => {
          this.commentList = list;
          this.buildCommentTree();
          this.zone.run(() => {});
        });
      }
    });
  }
  saveComment() {
    this.commentService.save(this.editCommentData, result => {
      if (result.status) {
        this.editCommentData = null;

        this.commentService.find({modelName: this.modelName, modelId: this.modelId}, list => {
          this.commentList = list;
          this.buildCommentTree();
          this.zone.run(() => {});
        });
      }
    });
  }
  deleteComment(comment) {
    this.commentService.delete(comment.id, result => {
      if (result.status) {
        this.commentService.find({modelName: this.modelName, modelId: this.modelId}, list => {
          this.commentList = list;
          this.buildCommentTree();
          this.zone.run(() => {});
        });
      }
    });
  }
  editComment(comment) {
    this.editCommentData = comment;
  }
  ngOnDestroy() {
    for (const s of this.subs) {
      s.unsubscribe();
    }
    this.subs = [];
  }
  reply(parentId) {
    this.commentData.parentId = parentId;
    this.zone.run(() => {});
  }
}
