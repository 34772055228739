import { Injectable } from '@angular/core';
import {GlobalSetting} from './global-setting';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommentModel} from '../Models/general-models';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private _url = 'apiComment';
  constructor(public globalSetting: GlobalSetting, public http: HttpClient) { }
  public find(conditions: {}, callback: (list: any[]) => void) {
    this.globalSetting.loadingMap['CommentS.find'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) {
        continue;
      }
      const v = conditions[k];
      if (v instanceof Array) {
        params = params.set(k, v.join(','));
      } else if (typeof v === 'string') {
        params = params.set(k, v);
      } else {
        params = params.set(k, v.toString());
      }
    }
    const options = {};
    options['params'] = params;
    this.http.get(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe((list: CommentModel[]) => {
        if (callback) { callback(list); }
      }, error => {
        if (callback) { callback([]); }
      }, () => {
        delete this.globalSetting.loadingMap['CommentS.find'];
      });
  }
  public create(data, callback: (result) => void) {
    this.globalSetting.loadingMap['CommentS.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    const options = {};
    options['params'] = params;
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe((result: any) => {
        callback(result);
      }, error => {
        callback(null);
      }, () => {
        delete this.globalSetting.loadingMap['CommentS.create'];
      });
  }
  public save(data, callback: (result) => void) {
    this.globalSetting.loadingMap['CommentS.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', data.id);
    const options = {};
    options['params'] = params;
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe((result: any) => {
        callback(result);
      }, error => {
        callback(null);
      }, () => {
        delete this.globalSetting.loadingMap['CommentS.create'];
      });
  }

  public delete(id, callback: (result) => void) {
    this.globalSetting.loadingMap['CommentS.delete'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', id);
    const options = {};
    options['params'] = params;
    this.http.delete(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => {
        delete this.globalSetting.loadingMap['CommentS.delete'];
      });
  }
}
