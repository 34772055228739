import {Component, Input, OnInit} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-user-header-avatar',
  templateUrl: './user-header-avatar.component.html',
  styleUrls: ['./user-header-avatar.component.css']
})
export class UserHeaderAvatarComponent implements OnInit {
  @Input() userProfile;
  @Input() baseUrl: string;
  constructor(public globalSetting: GlobalSetting) { }

  ngOnInit() {
  }

}
