import {Component, Input, OnInit} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-user-header-cover',
  templateUrl: './user-header-cover.component.html',
  styleUrls: ['./user-header-cover.component.css']
})
export class UserHeaderCoverComponent implements OnInit {
  @Input() userProfile;
  @Input() baseUrl: string;
  constructor(public globalSetting: GlobalSetting) {}
  ngOnInit() {
  }

}
