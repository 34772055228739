import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ToNiceHtmlPipe } from './pipes/to-nice-html.pipe';
import { DescToNiceHtmlPipe } from './pipes/desc-to-nice-html.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { LightboxModule } from 'angular2-lightbox';
import { BsDropdownModule, CarouselModule, CollapseModule, ModalModule } from 'ngx-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { getJWTToken } from 'src/app/services/global-setting';
import { PageHeadingComponent } from 'src/app/share/page-heading/page-heading.component';
import {FollowPageComponent} from './follow-page/follow-page.component';
import {HomeComponent} from './home/home.component';
import {ImageListInputComponent} from './image-list-input/image-list-input.component';
import {ImageUploadComponent} from './image-upload/image-upload.component';
import {LikeCommentComponent} from './like-comment/like-comment.component';
import {MyContryPageComponent} from './my-contry-page/my-contry-page.component';
import {MyCountryFormComponent} from './my-country-form/my-country-form.component';
import {MyCountryViewComponent} from './my-country-view/my-country-view.component';
import {PostFormComponent} from './post-form/post-form.component';
import {PostViewComponent} from './post-view/post-view.component';
import {ProfileComponent} from './profile/profile.component';
import {ServerConfigComponent} from './server-config/server-config.component';
import {UserHeaderAvatarComponent} from './user-header-avatar/user-header-avatar.component';
import {UserHeaderCoverComponent} from './user-header-cover/user-header-cover.component';
import {UserHeaderMenuComponent} from './user-header-menu/user-header-menu.component';
import {UserPageComponent} from './user-page/user-page.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {routing} from './app.routing';
import {DataStorage} from './services/DataStorage';
import { PostPageComponent } from './post-page/post-page.component';
import { MyCountryTypePageComponent } from './my-country-type-page/my-country-type-page.component';
import { SearchResultBoxComponent } from './search-result-box/search-result-box.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ShareButtonsModule} from '@ngx-share/buttons';
import { StoryPageComponent } from './story-page/story-page.component';
import { StoryFormComponent } from './story-form/story-form.component';
import { StoryViewComponent } from './story-view/story-view.component';
import { UserStoryPageComponent } from './user-story-page/user-story-page.component';
import {FamilyFriendPageComponent} from './family-friend-page/family-friend-page.component';
import { MyPlacesComponent } from './my-place/components/my-places/my-places.component';
import { MyPlacesCategoriesBlockComponent } from './my-place/components/my-places-categories-block/my-places-categories-block.component';
import { MyPlacesDetailsComponent } from './my-place/components/my-places-details/my-places-details.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SafeUrlPipe,
    ToNiceHtmlPipe,
    DescToNiceHtmlPipe,
    DateTimePipe,
    AppComponent,
    PageHeadingComponent,
    FollowPageComponent,
    FamilyFriendPageComponent,
    HomeComponent,
    ImageListInputComponent,
    ImageUploadComponent,
    LikeCommentComponent,
    MyContryPageComponent,
    MyCountryFormComponent,
    MyCountryViewComponent,
    PageNotFoundComponent,
    PostFormComponent,
    PostViewComponent,
    ProfileComponent,
    ServerConfigComponent,
    UserHeaderAvatarComponent,
    UserHeaderCoverComponent,
    UserHeaderMenuComponent,
    UserPageComponent,
    PostPageComponent,
    MyCountryTypePageComponent,
    SearchResultBoxComponent,
    StoryPageComponent,
    StoryFormComponent,
    StoryViewComponent,
    UserStoryPageComponent,
    MyPlacesComponent,
    MyPlacesCategoriesBlockComponent,
    MyPlacesDetailsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    // NoopAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LightboxModule,
    CarouselModule.forRoot(),
    CollapseModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        headerName: 'Token',
        tokenGetter: getJWTToken,
        whitelistedDomains: ['localhost:3001', 'goegeo.com', 'geogoe.com', 'geogoe.tk'],
        skipWhenExpired: false
      }
    }),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    InfiniteScrollModule,
    HttpClientJsonpModule, // (Optional) for Tumblr counts
    ShareButtonsModule,
    routing
  ],
  providers: [
    DataStorage,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
