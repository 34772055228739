import {Injectable} from '@angular/core';
import {GlobalSetting} from './global-setting';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MyCountry} from '../Models/MyCountry';

@Injectable({
  providedIn: 'root'
})
export class MyCountryService {
  private _url = 'apiMyCountry';

  constructor(private globalSetting: GlobalSetting, private http: HttpClient) {
  }

  public find(conditions: {}, callback: (list: MyCountry[]) => void) {
    this.globalSetting.loadingMap['MCS.find'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) { continue; }
      const v = conditions[k];
      if (v === null) { continue; }
      if (v instanceof Array) {
        // params = params.set(k, v.join(','));
        for (let i = 0; i < v.length; i++) {
          const sv = v[i];
          params = params.set(k + '[' + i + ']', sv);
        }
      } else if (typeof v === 'string') {
        params = params.set(k, v);
      } else {
        params = params.set(k, v.toString());
      }
    }
    const options = {params: params};
    this.http.get<MyCountry[]>(this.globalSetting.activeServer.getValue().base_url +
      this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe((list: MyCountry[]) => {
        if (callback) { callback(list); }
      }, error => {
        if (callback) { callback([]); }
      }, () => delete this.globalSetting.loadingMap['MCS.find'] );
  }

  public create(data, callback: (result) => void) {
    this.globalSetting.loadingMap['MCS.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    const options = {params: params};
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => delete this.globalSetting.loadingMap['MCS.create'] );
  }

  public edit(id: number, data, callback: (result) => void) {
    this.globalSetting.loadingMap['MCS.edit'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', id.toString());
    const options = {params: params};
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => delete this.globalSetting.loadingMap['MCS.edit'] );
  }

  public delete(id: number, callback: (result) => void) {
    this.globalSetting.loadingMap['MCS.delete'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', id.toString());
    const options = {params: params};
    this.http.delete(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(result => {
        callback(result);
        delete this.globalSetting.loadingMap['MCS.delete'];
      }, error => {
        callback(null);
      }, () => {
      delete this.globalSetting.loadingMap['MCS.delete'];
    });
  }

}
