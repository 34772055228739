import {Component, HostListener, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {UserImageService} from '../services/user-image.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})

export class ImageUploadComponent implements OnInit {
  image: string = null;
  status = null;
  result = null;
  @Input() autoSave: boolean;
  @ViewChild('chooseFileInput') private chooseFileInput;
  @ViewChild('imageTemp') private imageTemp;
  @Input() callback: (data: {imageData: string, dimension: {width: number, height: number}, type: string, size: number}) => void;
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.status = null;
  }
  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.status = null;
    }
  }
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.status = 'drad-over';
    }
  }
  constructor(private zone: NgZone, public userImage: UserImageService) { }
  ngOnInit() {
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result ? myReader.result.toString() : null;
      this.zone.run(() => {});
      setTimeout(() => {
          this.result = {
            imageData: this.image,
            width: this.imageTemp.nativeElement.naturalWidth,
            height: this.imageTemp.nativeElement.naturalHeight,
            type: file.type,
            size: file.size
          };
          if (this.autoSave) {
            this.uploadFile();
          }
      }, 100);
    };
    myReader.readAsDataURL(file);
  }
  openFileDialog(event: Event) {
    this.chooseFileInput.nativeElement.click();
  }

  uploadFile() {
    this.userImage.create(this.result, (result) => {
      console.log(result);
      if (result.status = true) {
        this.callback(result.data);
        this.image = null;
        this.result = null;
      }
    });
  }
}
