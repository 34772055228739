import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Post } from 'src/app/Models/Post';
import { slideInDownAnimation } from 'src/app/animations';
import { GlobalSetting } from 'src/app/services/global-setting';
import { PostService } from 'src/app/services/post.service';
import { SwapDataService } from 'src/app/services/swap-data.service';
import { UserAuthentication } from 'src/app/services/user-authentication';

@Component({
  selector: 'app-my-places',
  templateUrl: './my-places.component.html',
  styleUrls: ['./my-places.component.css'],
  animations: [slideInDownAnimation]
})
export class MyPlacesComponent implements OnInit {
  @HostBinding('@routeAnimation') routeAnimation = true;
  private subscriptions: Subscription[] = [];
  followData: { followedIds: number[], followingIds: number[] } = {followedIds: [], followingIds: []};
  page = 1;
  postLastId = null;
  posts: Post[] = [];
  postIsLoading = true;
  postEndOfList = false;
  nickname: string = null;
  userProfile = null;
  public likeOrDontLike = null;
  status: BehaviorSubject<{ [id: string]: boolean }> = new BehaviorSubject({});
  editPost: Post = null;
  edit = false;
  constructor(private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public userAuth: UserAuthentication
    , public router: Router
    , private postService: PostService
    , private swapData: SwapDataService
    , private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      console.log(params);
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
            const status1 = this.status.getValue();
            if (!status1.hasOwnProperty('userProfile') || status1['userProfile'] === false) {
              status1['userProfile'] = true;
              this.status.next(status);
            }
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
      if (params.hasOwnProperty('likeOrDontLike')) {
        this.likeOrDontLike = params['likeOrDontLike'];
      }

      const status = this.status.getValue();
      if (!status.hasOwnProperty('routeParams') || status['routeParams'] === false) {
        status['routeParams'] = true;
        this.status.next(status);
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) { continue; }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            const up = this.userProfile;
            this.userProfile = t;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            const up = this.userProfile;
            this.userProfile = t;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data1 => {
          if (data1) {
            const up = this.userProfile;
            this.userProfile = data1;
            if (up === null) {
              const status = this.status.getValue();
              status['userProfile'] = true;
              this.status.next(status);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.status.subscribe(value => {
      console.log(value);
      if (value.hasOwnProperty('userProfile') && value.hasOwnProperty('routeParams') &&
        value['userProfile'] && value['routeParams']) {
        // TODO: load data
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
            this.userAuth.loadUserProfile(followingItem.toId, () => {});
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
          }
          this.loadPost();
        }, true);
      }
    }));
  }
  loadPost(forceLoad = false) {
    if (forceLoad === false && this.swapData.get('UserPage.postData')) {
      const cacheData = this.swapData.get('UserPage.postData');
      if (
        this.nickname === cacheData.nickname &&
        this.userProfile.account.id === cacheData.userId &&
        this.likeOrDontLike === cacheData.likeOrDontLike) {
        this.posts = cacheData.posts;
        this.postLastId = cacheData.postLastId;
        console.log('data cached is used');
        this.postIsLoading = false;
        return;
      }

    }
    const condition = {authorId: [this.userProfile.account.id], lastId: this.postLastId};
    if (this.nickname
    // !this.userAuth.userData || this.userProfile.account.id !== this.userAuth.userData.id
    ) {
      // show another user page
    } else {
      // show wall page
      if (this.followData.followingIds) {
        for (const id of this.followData.followingIds) {
          condition.authorId.push(id);
        }
      }
    }
    if (this.likeOrDontLike !== null) {
      if (this.likeOrDontLike === 'i-like') {
        condition['iLike'] = 1;
      } else if (this.likeOrDontLike === 'i-dont-like') {
        condition['iLike'] = 0;
      }
    }
    this.postService.findMyPlace(condition, list => {
      if (list.length === 0) {
        this.postEndOfList = true;
      }
      for (const item of list) {
        this.userAuth.loadUserProfile(item.authorId, () => {
        });
        this.posts.push(item);
        if (this.postLastId === null) {
          this.postLastId = item.id;
        } else if (this.postLastId > item.id) {
          this.postLastId = item.id;
        }
        this.swapData.set('UserPage.postData', {
          posts: this.posts,
          postLastId: this.postLastId,
          nickname: this.nickname,
          likeOrDontLike: this.likeOrDontLike,
          userId: this.userProfile.account.id
        });
      }
      this.postIsLoading = false;
    });
  }
  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }
  generatePost(type) {
    if (!this.editPost) {
      this.editPost = {
        content: '',
        title: '',
        images: [],
        published: 1,
        type: 'my-place'
      };
    }
    if (type === 'place') {
      this.editPost.postCatId = 1;
    } else if (type === 'subject') {
      this.editPost.postCatId = 2;
    }
    console.log(this.editPost);
  }
  generatePostCallback() {
    return (postEdit: Post) => {
      if (this.userAuth.userData && this.userProfile.account.id === this.userAuth.userData.id) {
        this.updatePostsAfterSave(postEdit);
      } else {
      }
    };
  }
  updatePostsAfterSave(postEdit) {
    let found = false;
    const l = [];
    for (const p of this.posts) {
      if (postEdit.id === p.id) {
        l.push(postEdit);
        this.editPost = null;
        found = true;
      } else {
        l.push(p);
      }
    }
    if (found === false) {
      if (
        this.likeOrDontLike === null
        || (postEdit.iLike === 1 && this.likeOrDontLike === 'i-like')
        || (postEdit.iLike === 0 && this.likeOrDontLike === 'i-dont-like')
      ) {
        l.unshift(postEdit);
      }
      this.editPost = null;
    }
    this.posts = l;
  }
  onScroll() {
    console.log('onScroll');
    this.postIsLoading = true;
    if (this.postEndOfList === false) {
      this.loadPost(true);
    }
  }

  follow() {
    if (this.followData.followedIds.indexOf(this.userAuth.userData.id) >= 0) {
      this.userAuth.unfollow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    }
  }

  editPostData(post: Post) {
    this.edit = true;
    this.editPost = post;
  }

  deletePostData(post: Post) {
    this.translate.get('Are you sure to want delete this post?').subscribe(cmessage => {
      if (confirm(cmessage)) {
        this.postService.delete(post.id, result => {
          if (result.status) {
            const l = [];
            for (const p of this.posts) {
              if (post.id === p.id) {
              } else {
                l.push(p);
              }
            }
            this.posts = l;
            this.translate.get('This post was deleted!').subscribe(message => {
              alert(message);
            });
          }
        });
      }
    });
  }

}
