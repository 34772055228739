import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {GlobalSetting} from '../services/global-setting';
import {Subscription} from 'rxjs/Subscription';
import {UserAuthentication} from '../services/user-authentication';
import {MyCountryService} from '../services/my-country.service';
import {MyCountry} from '../Models/MyCountry';
import {TranslateService} from '@ngx-translate/core';
import {GeneralDataService} from '../services/general-data.service';
import {Lightbox} from 'angular2-lightbox';

@Component({
  selector: 'app-my-country-view',
  templateUrl: './my-country-view.component.html',
  styleUrls: ['./my-country-view.component.css']
})
export class MyCountryViewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  myCountryData: MyCountry = null;
  album = [];
  myCountries;
  edit = false;
  constructor(private activatedRoute: ActivatedRoute
    , public globalSetting: GlobalSetting
    , public generalData: GeneralDataService
    , public userAuth: UserAuthentication
    , public router: Router
    , public translate: TranslateService
    , private _lightbox: Lightbox
    , private myCountryService: MyCountryService) {
    this.generalData.loadCountry();
  }
  followData;
  userProfile;
  nickname;
  myCountryId;
  userStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ngOnInit() {
    this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('userId')) {
        this.nickname = params['userId'];
        this.userAuth.loadUserProfileBynickname(this.nickname, data => {
          if (data) {
            this.userProfile = data;
          } else {
            this.router.navigate(['pageNotFound']);
          }
        }, true);
      }
      if (params.hasOwnProperty('id')) {
        this.myCountryId = params['id'];
        this.loadMyCountryData();
      }
    }));
    this.subscriptions.push(this.userAuth.userProfileList.subscribe(list => {
      for (const item in list) {
        if (!list.hasOwnProperty(item)) { continue; }
        const t = list[item];
        if (this.nickname) {
          if (t.account.nickname === this.nickname) {
            this.userProfile = t;
            if (!this.userStatus.getValue()) {
              this.userStatus.next(true);
            }
            break;
          }
        } else {
          if (this.userAuth.userData && this.userAuth.userData.id === t.account.id) {
            this.userProfile = t;
            if (!this.userStatus.getValue()) {
              this.userStatus.next(true);
            }
            break;
          }
        }
      }
    }));
    this.subscriptions.push(this.userAuth.userDataChanged$.subscribe(value => {
      if (!this.nickname && value.id > 0) {
        this.userAuth.loadUserProfile(value.id, data1 => {
          if (data1) {
            this.userProfile = data1;
            if (!this.userStatus.getValue()) {
              this.userStatus.next(true);
            }
          }
        });
      }
    }));
    this.subscriptions.push(this.userStatus.subscribe(value => {
      if (value === true) {
        // TODO: load data
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
          }
          this.loadMyCountry();
        }, true);
      }
    }));
  }
  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }

  loadMyCountryData() {
    this.myCountryService.find({id: this.myCountryId}, list => {
      if (list.length > 0) {
        this.myCountryData = list[0];
        this.album = [];
        if (this.myCountryData.images) {
          for (const i of this.myCountryData.images) {
            this.album.push({src: this.globalSetting.activeServer.getValue().base_url + '/user_images/' + i});
          }
        }
      } else {
        this.router.navigate(['pageNotFound']);
      }
    });
  }

  loadMyCountry() {
    const condition = {authorId: [this.userProfile.account.id]};
    this.myCountries = [];
    if (!this.userAuth.userData || this.userProfile.account.id !== this.userAuth.userData.id) {
      // show another user page
    } else {
      // show wall page
      if (this.followData.followingIds) {
        for (const id of this.followData.followingIds) {
          condition.authorId.push(id);
        }
      }
    }
    this.myCountryService.find(condition, list => {
      for (const item of list) {
        this.myCountries.push(item);
      }
    });
  }
  open(i) {
    this._lightbox.open(this.album, i);
  }
  follow() {
    if (this.followData.followedIds.indexOf(this.userAuth.userData.id) >= 0) {
      this.userAuth.unfollow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, () => {
              }, true);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(this.userProfile.account.id, result => {
        if (result.status) {
          this.userAuth.loadUserProfileBynickname(this.nickname, data => {
            if (data) {
              this.userProfile = data;
              this.userAuth.getFollowData(this.userProfile.account.id, fresult => {
                this.followData = {followedIds: [], followingIds: []};
                for (const followingItem of fresult.followingList) {
                  this.followData.followingIds.push(followingItem.toId);
                }
                for (const followedItem of fresult.followedList) {
                  this.followData.followedIds.push(followedItem.fromId);
                }
              }, true);
            } else {
              this.router.navigate(['pageNotFound']);
            }
          }, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {
            if (data) {
              this.userAuth.getFollowData(this.userAuth.userData.id, fresult => {
              }, true);
            }
          }, true);
        }
      });
    }
  }

  generateMyCountryCallback() {
    return (status) => {
      if (this.userAuth.userData && this.userProfile.account.id === this.userAuth.userData.id) {
        this.loadMyCountry();
        this.edit = false;
      } else {
      }
    };
  }
  editMyCountry() {
    this.edit = true;
  }
  deleteMyCountry() {
    this.translate.get('Are you sure to want delete this post?').subscribe(cmessage => {
      if (confirm(cmessage)) {
        this.myCountryService.delete(this.myCountryData.id, result => {
          if (result.status) {
            this.translate.get('This post was deleted!').subscribe(message => {
              alert(message);
              this.router.navigate(['/pages', this.userProfile.account.nickname]);
            });
          }
        });
      }
    });
  }
}
