import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Subscription} from 'rxjs/Subscription';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {UserAuthentication} from '../services/user-authentication';
import {GlobalSetting} from '../services/global-setting';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userProfile;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  followData: {followedIds: number[], followingIds: number[]} = {followedIds: [], followingIds: []};
  constructor(
    public globalSetting: GlobalSetting,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    public userAuth: UserAuthentication) { }
  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
      })
    );
    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {
      })
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        const _reason = reason ? ', dismissed by ${reason}' : '';
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.unsubscribe();
      }));

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template);
  }
  ngOnInit() {
    this.userAuth.userProfileList.subscribe( list => {
      if (Object.keys(list).length > 0 && list[this.userAuth.userData.id]) {
        this.userProfile = list[this.userAuth.userData.id];
        this.userAuth.getFollowData(this.userProfile.account.id, result => {
          this.followData = {followedIds: [], followingIds: []};
          for (const followingItem of result.followingList) {
            this.followData.followingIds.push(followingItem.toId);
          }
          for (const followedItem of result.followedList) {
            this.followData.followedIds.push(followedItem.fromId);
          }
        }, true);
      }
    });
  }
  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  receiveAvatar(value) {
    this.userAuth.updateProfile({avatar: value.filename}, result => {
      if (result && result.status) {
        this.userAuth.loadUserProfile(this.userAuth.userData.id, () => {}, true);
        this.modalRef.hide();
      }
    });
  }
  receiveCover(value) {
    this.userAuth.updateProfile({cover: value.filename}, result => {
      if (result && result.status) {
        this.userAuth.loadUserProfile(this.userAuth.userData.id, () => {}, true);
        this.modalRef.hide();
      }
    });
  }
  generateUpdateAvatarFunc() {
    return (value) => {
      this.receiveAvatar(value);
    };
  }
  generateUpdateCoverFunc() {
    return (value) => {
      this.receiveCover(value);
    };
  }
  save() {
    this.userAuth.updateProfile({
      infoTitle: this.userProfile.profile.infoTitle,
      info: this.userProfile.profile.info,
      infoImages: this.userProfile.profile.infoImages,
    }, (() => {
      this.userAuth.loadUserProfile(this.userProfile.account.id, () => {}, true);
    }));
  }
}
