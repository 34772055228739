import { Injectable } from '@angular/core';
import {GlobalSetting} from './global-setting';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Story} from '../Models/Story';
import {StoryCategory} from '../Models/StoryCategory';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  private _url = 'story/apiStory';
  private _catUrl = 'story/apiStoryCat';
  constructor(private globalSetting: GlobalSetting, private http: HttpClient) { }
  public find(conditions: {}, callback: (list: Story[]) => void) {
    this.globalSetting.loadingMap['SS.find'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) { continue; }
      const v = conditions[k];
      if (v instanceof Array) {
        // params = params.set(k, v.join(','));
        for (let i = 0; i < v.length; i++) {
          const sv = v[i];
          params = params.set(k + '[' + i + ']', sv);
        }
      } else if (typeof v === 'string') {
        params = params.set(k, v);
      } else if (v === null) {

      } else {
        params = params.set(k, v.toString());
      }
    }
    const options = {params: params};
    this.http.get<Story[]>(this.globalSetting.activeServer.getValue().base_url +
      this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(list => {
        if (callback) { callback(list); }
      }, error => {
        if (callback) { callback([]); }
      }, () => delete this.globalSetting.loadingMap['SS.find'] );
  }
  public findCat(conditions: {}, callback: (list: StoryCategory[]) => void) {
    this.globalSetting.loadingMap['SS.findCat'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._catUrl);
    for (const k in conditions) {
      if (!conditions.hasOwnProperty(k)) { continue; }
      const v = conditions[k];
      if (v instanceof Array) {
        // params = params.set(k, v.join(','));
        for (let i = 0; i < v.length; i++) {
          const sv = v[i];
          params = params.set(k + '[' + i + ']', sv);
        }
      } else if (typeof v === 'string') {
        params = params.set(k, v);
      } else if (v === null) {

      } else {
        params = params.set(k, v.toString());
      }
    }
    const options = {params: params};
    this.http.get<StoryCategory[]>(this.globalSetting.activeServer.getValue().base_url +
      this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(list => {
        if (callback) { callback(list); }
      }, error => {
        if (callback) { callback([]); }
      }, () => delete this.globalSetting.loadingMap['SS.findCat'] );
  }

  public create(data, callback: (result) => void) {
    this.globalSetting.loadingMap['SS.create'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    const options = {params: params};
    this.http.put(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => delete this.globalSetting.loadingMap['SS.create'] );
  }

  public edit(id: number, data, callback: (result) => void) {
    this.globalSetting.loadingMap['SS.edit'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', id.toString());
    const options = {params: params};
    this.http.post(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile,
      JSON.stringify(data), options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => delete this.globalSetting.loadingMap['SS.edit'] );
  }

  public delete(id: number, callback: (result) => void) {
    this.globalSetting.loadingMap['SS.delete'] = true;
    let params: HttpParams = new HttpParams();
    params = params.set('r', this._url);
    params = params.set('id', id.toString());
    const options = {params: params};
    this.http.delete(this.globalSetting.activeServer.getValue().base_url + this.globalSetting.activeServer.getValue().scriptFile, options)
      .subscribe(result => {
        callback(result);
      }, error => {
        callback(null);
      }, () => delete this.globalSetting.loadingMap['SS.delete'] );
  }
}
