import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwapDataService {
  private componentCacheData = {};
  constructor() { }
  set(id, data) {
    if (data === null) {
      if (this.componentCacheData.hasOwnProperty(id)) {
        delete this.componentCacheData[id];
      }
    } else {
      this.componentCacheData[id] = data;
    }
  }
  get(id) {
    if (this.componentCacheData.hasOwnProperty(id)) {
      return this.componentCacheData[id];
    } else {
      return null;
    }
  }
}
