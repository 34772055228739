let outStandStorage;

function getStorage(): Storage {
  if (outStandStorage) {
    return outStandStorage;
  } else {
    outStandStorage = window.localStorage;
  }
  return outStandStorage;
}

export class DataStorage {
  public static getItem(key: string, type: string = 'string') {
    const storage = getStorage();
    let data = storage.getItem(key);
    if (type === 'json' || type === 'JSON') {
      if (data === null || data === '') {
        data = null;
      } else {
        data = JSON.parse(data);
      }
    }
    return data;
  }

  public static getJSONItem(key: string, type: string = 'json'): {} {
    const storage = getStorage();
    let data = storage.getItem(key);
    if (type === 'json' || type === 'JSON') {
      if (data === null || data === '') {
        data = null;
      } else {
        data = JSON.parse(data);
      }
    }
    return data;
  }

  public static setItem(key: string, data, type: string = 'string') {
    const storage = getStorage();
    if (type === 'json' || type === 'JSON') {
      if (data === null || data === '') {
        data = null;
      } else {
        data = JSON.stringify(data);
      }
    }
    if (data === null) {
      storage.removeItem(key);
    }
    storage.setItem(key, data);
  }

  public static setJSONItem(key: string, data, type: string = 'json') {
    const storage = getStorage();
    if (type === 'json' || type === 'JSON') {
      if (data === null || data === '') {
        data = null;
      } else {
        data = JSON.stringify(data);
      }
    }
    if (data === null) {
      storage.removeItem(key);
    }
    storage.setItem(key, data);
  }

  public static key(index: number) {
    const storage = getStorage();
    return storage.key(index);
  }

  public static removeItem(key: string) {
    const storage = getStorage();
    return storage.removeItem(key);
  }

  public static clear() {
    const storage = getStorage();
    return storage.clear();
  }
}
