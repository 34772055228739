import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {MyCountry} from '../Models/MyCountry';
import {MyCountryService} from '../services/my-country.service';
import {UserAuthentication} from '../services/user-authentication';
import {GeneralDataService} from '../services/general-data.service';

@Component({
  selector: 'app-my-country-form',
  templateUrl: './my-country-form.component.html',
  styleUrls: ['./my-country-form.component.css']
})
export class MyCountryFormComponent implements OnInit {
  @Input() myCountry: MyCountry;
  @Input() notMyCountry: boolean;
  status = true;
  @Output('myCountry') change: EventEmitter<MyCountry> = new EventEmitter<MyCountry>();
  @Input() callback: (status: MyCountry) => void;
  constructor(
    public myCountryService: MyCountryService
    , public generalData: GeneralDataService
    , public zone: NgZone
    , public userAuth: UserAuthentication) {

  }
  countryList = [];
  ngOnInit() {
    this.generalData.loadCountry();
    this.generalData.countryList.subscribe(list => {
      this.countryList = [];
      const userProfiles = this.userAuth.userProfileList.getValue();
      // console.log(userProfiles[this.userAuth.userData.id].profile.countryId);
      // for (let key in list) {
      //   if (list.hasOwnProperty(key)) {
      //     if (userProfiles[this.userAuth.userData.id].profile.countryId === key) continue;
      //     this.countryList.push(list[key]);
      //   }
      // }
      const listByArea = this.generalData.areaCodes;
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          if (userProfiles[this.userAuth.userData.id] && userProfiles[this.userAuth.userData.id].profile.countryId === key) {
            continue;
          }
          if (!listByArea[list[key]['areaCode']].hasOwnProperty('children')) {
            listByArea[list[key]['areaCode']]['children'] = [];
          }
          listByArea[list[key]['areaCode']]['children'].push(list[key]);
        }
      }
      for (const ac in listByArea) {
        if (!listByArea.hasOwnProperty(ac)) {
          continue;
        }
        this.countryList.push(listByArea[ac]);
      }
    });
  }
  save(iLike) {
    if (this.myCountry.id) {
      this.myCountryService.edit(this.myCountry.id, this.myCountry, result => {
        if (result.status) {
          this.myCountry = result.data;
          this.change.emit(this.myCountry);
          if (this.callback) {
            this.callback(this.myCountry);
            alert('Successfully created!');
          }
        }
      });
    } else {
      this.myCountry.iLike = iLike;
      this.myCountryService.create(this.myCountry, result => {
        if (result.status) {
          this.myCountry = result.data;
          this.change.emit(this.myCountry);
          this.zone.run(() => {});
          if (this.callback) {
            this.callback(this.myCountry);
            alert('Successfully created!');
          }
        }
      });
    }
  }
}
