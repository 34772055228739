import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GlobalSetting} from '../services/global-setting';
import {MyCountryService} from '../services/my-country.service';
import {PostService} from '../services/post.service';
import {UserAuthentication} from '../services/user-authentication';
import {Post} from '../Models/Post';

@Component({
  selector: 'app-search-result-box',
  templateUrl: './search-result-box.component.html',
  styleUrls: ['./search-result-box.component.css']
})
export class SearchResultBoxComponent implements OnInit, OnChanges {
  @Input() keyword: string;
  followData: { followedIds: number[], followingIds: number[] };
  @Output('keyword') change: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleFollow: EventEmitter<string> = new EventEmitter<string>();
  searchResults = {members: null, myCountries: null, posts: null};
  public isSearching = {members: false, myCountries: false, posts: false};
  private timeOutSubject = null;
  constructor(
    public globalSetting: GlobalSetting,
    public myCountryService: MyCountryService,
    public postService: PostService,
    public userAuth: UserAuthentication
  ) { }
  ngOnInit() {
    this.userAuth.getFollowData(this.userAuth.userData.id, (fresult) => {
      this.followData = {followedIds: [], followingIds: []};
      for (const followingItem of fresult.followingList) {
        this.followData.followingIds.push(followingItem.toId);
      }
      for (const followedItem of fresult.followedList) {
        this.followData.followedIds.push(followedItem.fromId);
      }
      console.log(this.followData);
    });
    this.userAuth.followDataChanged$.subscribe((list) => {
      if (list[this.userAuth.userData.id]) {
        const fresult1 = list[this.userAuth.userData.id];
        this.followData = {followedIds: [], followingIds: []};
        for (const followingItem of fresult1.followingList) {
          this.followData.followingIds.push(followingItem.toId);
        }
        for (const followedItem of fresult1.followedList) {
          this.followData.followedIds.push(followedItem.fromId);
        }
        console.log(this.followData);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('keyword') && this.keyword && this.keyword.length >= 3) {
      if (this.timeOutSubject) {
        clearTimeout(this.timeOutSubject);
      }
      this.timeOutSubject = setTimeout(() => {
        this.search();
      }, 300);
    }
  }
  close() {
    if (this.timeOutSubject) { clearTimeout(this.timeOutSubject); }
    this.isSearching = {members: false, myCountries: false, posts: false};
    this.keyword = '';
    this.searchResults = {members: [], myCountries: [], posts: []};
  }
  search() {
    if (this.isSearching.members || this.isSearching.myCountries || this.isSearching.posts) {
      if (this.timeOutSubject) {
        clearTimeout(this.timeOutSubject);
      }
      this.timeOutSubject = setTimeout(() => this.search(), 300);
      return;
    }
    this.isSearching.members = true;
    this.userAuth.searchUserProfile({keyword: this.keyword}, data => {
      this.searchResults.members = data;
      this.isSearching.members = false;
    });
    this.isSearching.myCountries = true;
    this.myCountryService.find({keyword: this.keyword}, data => {
      this.searchResults.myCountries = data;
      const mcUids = [];
      for (const mcItem of data) {
        if (mcUids.indexOf(mcItem.authorId) === -1) {
          mcUids.push(mcItem.authorId);
        }
      }
      this.userAuth.searchUserProfile({id: mcUids}, () => {});
      this.isSearching.myCountries = false;
    });
    this.isSearching.posts = true;
    this.postService.find({keyword: this.keyword}, data => {
      this.searchResults.posts = data;
      const pUids = [];
      for (const pItem of data) {
        if (pUids.indexOf(pItem.authorId) === -1) {
          pUids.push(pItem.authorId);
        }
      }
      this.userAuth.searchUserProfile({id: pUids}, () => {});
      this.isSearching.posts = false;
      console.log(this.searchResults);
    });
  }
  follow(id: number) {
    if (this.followData.followingIds.indexOf(id) >= 0) {
      this.userAuth.unfollow(id.toString(), result => {
        if (result.status) {
          this.userAuth.getFollowData(id, () => {}, true);
          this.userAuth.loadUserProfile(id, data => {}, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {}, true);
          this.userAuth.getFollowData(this.userAuth.userData.id, (fresult) => {
            this.followData = {followedIds: [], followingIds: []};
            for (const followingItem of fresult.followingList) {
              this.followData.followingIds.push(followingItem.toId);
            }
            for (const followedItem of fresult.followedList) {
              this.followData.followedIds.push(followedItem.fromId);
            }
          }, true);
        }
      });
    } else {
      this.userAuth.follow(id.toString(), result => {
        if (result.status) {
          this.userAuth.getFollowData(id, () => {}, true);
          this.userAuth.loadUserProfile(id, data => {}, true);
          this.userAuth.loadUserProfile(this.userAuth.userData.id, data => {}, true);
          this.userAuth.getFollowData(this.userAuth.userData.id, (fresult) => {
            this.followData = {followedIds: [], followingIds: []};
            for (const followingItem of fresult.followingList) {
              this.followData.followingIds.push(followingItem.toId);
            }
            for (const followedItem of fresult.followedList) {
              this.followData.followedIds.push(followedItem.fromId);
            }
          }, true);
        }
      });
    }
  }
}
